import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PencilFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

import Form from "react-bootstrap/Form";

import { setDeviceAlias } from "../../store/devices-actions";

import { selectCompanyApiToken, selectUserType } from "../../store/user-slice";
import { selectSessionsList } from "../../store/devices-slice";

import { USERTYPES } from "../../store/models/user-types";

import classes from "./AllSessions.module.css";

import SessionsList from "./SessionsList";
import NoSessions from "./NoSessions";

const AllPassings = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refDeviceAlias = useRef();
  const companyApiToken = useSelector(selectCompanyApiToken);
  const userType = useSelector(selectUserType);
  const sessions = useSelector(selectSessionsList);
  const [showEdit, setShowEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const currentDevice = props.currentDevice;
  const numOfSessions = sessions ? sessions.length : 0;

  const enterMouseHandler = () => {
    if (userType !== USERTYPES.READ_ONLY) setShowEdit(true);
  };

  const leaveMouseHandler = () => {
    !isEditing && setShowEdit(false);
  };

  const saveDeviceAlias = () => {
    if (isEditing) {
      const newAlias = refDeviceAlias.current.value;
      const deviceId = currentDevice.id;

      if (newAlias !== currentDevice.alias)
        dispatch(setDeviceAlias(companyApiToken, deviceId, newAlias));
    }
    setIsEditing(!isEditing);

    isEditing && setShowEdit(true);
  };

  const clickHandler = () => {
    saveDeviceAlias();
  };

  const keyPressHandler = (event) => {
    const key = event.keyCode;

    if (key === 13) saveDeviceAlias();
  };

  return (
    <>
      <div
        onMouseEnter={enterMouseHandler}
        onMouseLeave={leaveMouseHandler}
        className={classes.edit}
      >
        {!isEditing && <h1> {currentDevice.alias} </h1>}{" "}
        {isEditing && (
          <Form.Control
            ref={refDeviceAlias}
            plaintext
            defaultValue={currentDevice.alias}
            autoFocus
            onKeyDown={keyPressHandler}
            maxLength={50}
          />
        )}{" "}
        {showEdit && (
          <PencilFill
            size={28}
            onClick={clickHandler}
            className={classes.pencil}
          />
        )}
      </div>
      <span>
        {t("There are")} {numOfSessions} {t("available timing sessions in this device.")}
      </span>
      {sessions && sessions.length > 0 && <SessionsList sessions={sessions} />}
      {sessions && sessions.length === 0 && <NoSessions />}
    </>
  );
};

export default AllPassings;
