import { useTranslation } from "react-i18next";

import classes from "./NoPassings.module.css";

import Image from 'react-bootstrap/Image'
import imgNoPassings from "../../assets/no-passings.png";

const NoPassings = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes["no-passings"]}>
        <span><b>{t("This is an empty session!")}</b></span>
        <Image rounded src={imgNoPassings} />
        {/* <Image src="holder.js/300x300?text=No passings image: holder_dimensions" /> */}
        <span>{t("Add some passings now!")}</span>
      </div>
    </div>
  );
};

export default NoPassings;
