import { API, graphqlOperation } from "aws-amplify";
// import { GraphQLAPI as API, graphqlOperation } from '@aws-amplify/api-graphql';

import {
  getUserByCredentials,
  getUserInvitations,
  getCollaborators,
  getUserApiKeys,
} from "../graphql/queries";
import {
  updateUser,
  createInvitation,
  removeInvitation,
  removeCollaborator,
  updateUserType,
  registerUser,
  createApiKey,
  removeApiKey
} from "../graphql/mutations";

import {
  setLoading,
  resetForm,
  setDynamicButtons,
  setDynamicLoading,
  addDynamicButton,
  removeDynamicButton,
} from "./ui-actions";

import { userActions } from "./user-slice";

import { ToastSuccess, ToastError } from "../components/Helpers/ToastHelper";

export const deleteApiKey = (companyApiToken, apiKey) => {
  return async (dispatch) => {
    dispatch(setDynamicLoading("buttonDeleteApiKey", apiKey, true));

    const response = await API.graphql(
      graphqlOperation(removeApiKey, {
        company_api_token: companyApiToken,
        api_key: apiKey
      })
    );

    dispatch(removeDynamicButton("buttonDeleteApiKey", apiKey));

    dispatch(
      userActions.removeApiKey({
        apiKey,
      })
    );

    ToastSuccess("ApiKeyDeleted");
  };
};

export const getApiKeys = (companyApiToken) => {
  return async (dispatch) => {
    const response = await API.graphql(
      graphqlOperation(getUserApiKeys, {
        company_api_token: companyApiToken,
      })
    );

    const apiKeys = response.data.getUserApiKeys;

    const ids = apiKeys.map((apiKey) => {
      return apiKey.api_key;
    });

    dispatch(setDynamicButtons("buttonDeleteApiKey", ids));

    dispatch(
      userActions.setApiKeys({
        apiKeys,
      })
    );
  };
};

export const createNewApiKey = (
  companyApiToken,
  alias,
  accessType,
  allowUnbind
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonCreateApiKey", true));

      const response = await API.graphql(
        graphqlOperation(createApiKey, {
          company_api_token: companyApiToken,
          alias: alias,
          access_type: accessType,
          allow_unbind: allowUnbind,
        })
      );
      const apiKey = response.data.createApiKey;

      dispatch(setLoading("buttonCreateApiKey", false));
      dispatch(resetForm("apiKeyForm"));
      dispatch(addDynamicButton("buttonDeleteApiKey", apiKey));

      dispatch(
        userActions.addApiKey({
          api_key: apiKey,
          alias,
          access_type: accessType,
          allow_unbind: allowUnbind
        })
      );

      ToastSuccess("ApiKeyCreated");
    } catch (error) {
      dispatch(setLoading("buttonCreateApiKey", false));
      ToastError(error);
    }
  };
};

export const updateOnboarding = (userApiToken, target, status) => {
  return async (dispatch) => {
    try {
      //TODO: Update onboarding en el back

      // const response = await API.graphql(
      //   graphqlOperation(createInvitation, {
      //     invitation: invitation,
      //   })
      // );
      // const newInvitation = response.data.createInvitation;

      dispatch(
        userActions.setOnboarding({
          target,
          status,
        })
      );
    } catch (error) {
      ToastError(error);
    }
  };
};

export const updateCollaboratorUserType = (id, userType) => {
  return async (dispatch) => {
    const response = await API.graphql(
      graphqlOperation(updateUserType, {
        id: id,
        user_type: userType,
      })
    );

    ToastSuccess("CollaboratorUserTypeUpdated");

    dispatch(
      userActions.updateCollaboratorUserType({
        id,
        userType,
      })
    );
  };
};

export const deleteCollaborator = (id) => {
  return async (dispatch) => {
    dispatch(setDynamicLoading("buttonDeleteCollaborator", id, true));
    const response = await API.graphql(
      graphqlOperation(removeCollaborator, {
        id: id,
      })
    );

    dispatch(removeDynamicButton("buttonDeleteCollaborator", id));

    dispatch(
      userActions.removeCollaborator({
        id,
      })
    );
    ToastSuccess("CollaboratorDeleted");
  };
};

export const getCollaboratorsList = (companyApiToken) => {
  return async (dispatch) => {
    const response = await API.graphql(
      graphqlOperation(getCollaborators, {
        company_api_token: companyApiToken,
      })
    );

    const collaborators = response.data.getCollaborators;
    const ids = collaborators.map((collaborator) => {
      return collaborator.id;
    });

    dispatch(setDynamicButtons("buttonDeleteCollaborator", ids));

    dispatch(
      userActions.setCollaborators({
        collaborators,
      })
    );
  };
};

export const deleteInvitation = (hash) => {
  return async (dispatch) => {
    dispatch(setDynamicLoading("buttonDeleteInvitation", hash, true));
    const response = await API.graphql(
      graphqlOperation(removeInvitation, {
        hash: hash,
      })
    );

    //dispatch(setDynamicLoading("buttonDeleteInvitation", hash, false)); ==> No es necesario ya que lo elimino en la próxima
    dispatch(removeDynamicButton("buttonDeleteInvitation", hash));

    dispatch(
      userActions.removePendingInvitations({
        hash,
      })
    );

    ToastSuccess("InvitationDeleted");
  };
};

export const sendInvitation = (invitation) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonSendInvitation", true));

      const response = await API.graphql(
        graphqlOperation(createInvitation, {
          invitation: invitation,
        })
      );
      const newInvitation = response.data.createInvitation;

      dispatch(setLoading("buttonSendInvitation", false));
      dispatch(resetForm("invitationForm"));
      dispatch(addDynamicButton("buttonDeleteInvitation", newInvitation.hash));

      dispatch(
        userActions.addPendingInvitation({
          newInvitation,
        })
      );

      ToastSuccess("InvitationSent");
    } catch (error) {
      dispatch(setLoading("buttonSendInvitation", false));
      ToastError(error);
    }
  };
};

export const getPendingInvitations = (userApiToken) => {
  return async (dispatch) => {
    const response = await API.graphql(
      graphqlOperation(getUserInvitations, {
        inviter_api_token: userApiToken,
      })
    );
    const invitations = response.data.getUserInvitations;
    const ids = invitations.map((invitation) => {
      return invitation.hash;
    });

    dispatch(setDynamicButtons("buttonDeleteInvitation", ids));

    dispatch(
      userActions.setPendingInvitations({
        invitations,
      })
    );
  };
};

export const getUserData = (email) => {
  console.log("getUserData:::");
  return async (dispatch) => {
    const response = await API.graphql(
      graphqlOperation(getUserByCredentials, {
        email: email,
      })
    );
    const user = response.data.getUserByCredentials;

    // console.log("getUserData:::", user);

    dispatch(
      userActions.setUser({
        user,
      })
    );
  };
};

export const updateUserProfile = (user, userApiToken) => {
  return async (dispatch) => {
    dispatch(setLoading("buttonSaveProfile", true));

    const updatedUser = {
      api_token: userApiToken,
      name: user.name,
      lastname: user.lastname,
      company: user.company,
      preferred_language: user.preferredLanguage,
      country: user.country,
      city: user.city,

      image_url: null,
      email_notifications: null,
      sms_notifications: null,
    };

    try {
      const response = await API.graphql(
        graphqlOperation(updateUser, {
          user: updatedUser,
        })
      );

      dispatch(setLoading("buttonSaveProfile", false));

      dispatch(
        userActions.updateUser({
          updatedUser,
        })
      );
      ToastSuccess("ProfileUpdated");
    } catch (error) {
      dispatch(setLoading("buttonSaveProfile", false));
      ToastError(error);
    }
  };
};

export const updateUserEmail = (newEmail, userApiToken) => {
  return async (dispatch) => {
    const updatedUser = {
      api_token: userApiToken,
      email: newEmail,
    };

    const response = await API.graphql(
      graphqlOperation(updateUser, {
        user: updatedUser,
      })
    );

    dispatch(
      userActions.updateUserEmail({
        updatedUser,
      })
    );
    //ToastSuccess("ProfileUpdated");
  };
};

export const updateUserPhone = (countryCode, phoneNumber, userApiToken) => {
  return async (dispatch) => {
    const updatedUser = {
      api_token: userApiToken,
      country_code: countryCode,
      telephone: phoneNumber,
    };

    const response = await API.graphql(
      graphqlOperation(updateUser, {
        user: updatedUser,
      })
    );

    dispatch(
      userActions.updateUserPhone({
        updatedUser,
      })
    );
    //ToastSuccess("ProfileUpdated");
  };
};

export const registerNewUser = (email, invitation) => {
  return async () => {
    const response = await API.graphql(
      graphqlOperation(registerUser, {
        email: email,
        invitation_hash: invitation,
      })
    );

    return response.data.registerUser;
  };
};

export const clearUserData = () => {
  return async (dispatch) => {
    dispatch(userActions.clearUser());
  };
};
