import toast from "react-hot-toast";
import i18n from "i18next";

export const ToastInfo = (code, payload = null) => {

  switch (code) {
    case "ApiKeyMaximumReached":
      toast(i18n.t(code), {
        icon: '🔥',
      });
      break;

    default:
      toast.info(code);
      break;
  }
};

export const ToastSuccess = (code, payload = null) => {
  switch (code) {
    case "ApiKeyDeleted":
      toast.success(i18n.t(code));

      break;

    case "ApiKeyCreated":
      toast.success(i18n.t(code));

      break;

    case "DeviceAliasUpdated":
      toast.success(i18n.t(code));

      break;

    case "NewTokenGenerated":
      const token = payload.token;

      toast.success(i18n.t(code) + token);
      break;

    case "ChangeEmailSuccess":
      toast.success(i18n.t(code));
      break;

    case "CollaboratorUserTypeUpdated":
      toast.success(i18n.t(code));
      break;

    case "ProfileUpdated":
      toast.success(i18n.t(code));
      break;

    case "CollaboratorDeleted":
      toast.success(i18n.t(code));
      break;

    case "InvitationDeleted":
      toast.success(i18n.t(code));
      break;

    case "InvitationSent":
      toast.success(i18n.t(code));
      break;

    case "ChangePasswordSuccess":
      toast.success(i18n.t(code));
      break;

    case "ResetPasswordSuccess":
      toast.success(i18n.t(code));
      break;

    case "NewEmailVerificationSuccess":
      toast.success(i18n.t(code));
      break;

    case "EmailVerificationSuccess":
      toast.success(i18n.t(code));
      break;

    case "PhoneVerificationSuccess":
      toast.success(i18n.t(code));
      break;

    case "PhoneRemoved":
      toast.success(i18n.t(code));
      break;

    case "PhoneVerificationPending":
      toast.success(i18n.t(code));
      break;

    case "EmailVerificationPending":
      toast.success(i18n.t(code));
      break;

    case "RecoveryTokenSent":
      toast.success(i18n.t(code));
      break;

    case "PhoneConfirmationCodeSent":
      toast.success(i18n.t(code));
      break;

    case "ConfirmationCodeSent":
      toast.success(i18n.t(code));
      break;

    default:
      toast.success(code);
      break;
  }
};

export const ToastError = (error, from = null) => {
  let code = error.code;
  let message = error.errors
    ? error.errors.length
      ? error.errors[0].message
      : error.message
    : error.message;

  console.log("ERROR:::code:", code);
  console.log("ERROR:::message:", message);

  switch (code) {
    //ERROR
    case "AliasExistsException":
      toast.error(i18n.t(code));
      break;

    case "InvalidPasswordException":
      toast.error(
        i18n.t(code)
      );
      break;

    case "UsernameExistsException":
      toast.error(i18n.t(code));
      break;

    case "LimitExceededException":
      toast.error(i18n.t(code));
      break;

    case "CodeMismatchException":
      toast.error(i18n.t(code));
      break;

    case "ExpiredCodeException":
      toast.error(i18n.t(code));
      break;

    case "InvalidParameterException":
    case "NotAuthorizedException":
      switch (from) {
        case "changePhoneNumber":
          toast.error(i18n.t(from));
          break;

        case "changePassword":
          toast.error(i18n.t(from));
          break;

        case "verifyNewPhone":
        case "verifyNewEmail":
          toast.error(i18n.t(from));
          break;

        default:
          toast.error(i18n.t("Wrong email or password"));
      }
      break;

    default:
      switch (message) {
        case "EmailAlreadyRegistered":
          toast.error(i18n.t(message));
          break;

        default:
          toast.error(message);
      }
  }
};
