import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationsEN from './en/en.json'
import translationsES from './es/es.json'
import translationsPO from './po/po.json'

const resources = {
  en: {
    translation: translationsEN
  },
  es: {
    translation: translationsES
  },
  po: {
    translation: translationsPO
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;