import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import classes from "./WelcomeModal.module.css";
import { updateOnboarding } from "../../store/user-actions";

import { selectOnboarding, selectUserApiToken } from "../../store/user-slice";

const NoDevices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onboarding = useSelector(selectOnboarding);
  const userApiToken = useSelector(selectUserApiToken);
  const messageSeen = onboarding.welcomeMessageSeen;
  const [show, setShow] = useState(!messageSeen);

  useEffect(() => {
    setShow(!messageSeen);
  }, [messageSeen]);

  const handleClose = () => {
    dispatch(updateOnboarding(userApiToken, "welcomeMessageSeen", true));
    setShow(false);
  };

  const handleDownload = (lang) => {
    let downloadLink =
      "https://drive.google.com/file/d/1Vw3yWinPBgLaFBe3WhEguLffBlPo4lr-/view?usp=share_link"; //EN

    switch (lang) {
      case "ES":
        downloadLink =
          "https://drive.google.com/file/d/1kpiCLd5YFl2XXWE209X6l6-sa9-I4Rm7/view?usp=share_link";
        break;
      case "PT":
        downloadLink =
          "https://drive.google.com/file/d/1ochY6d_yCHwElnU-FX9tmJbRyGAbgC2x/view?usp=share_link";
        break;
      case "EN":
        downloadLink =
          "https://drive.google.com/file/d/1Vw3yWinPBgLaFBe3WhEguLffBlPo4lr-/view?usp=share_link";
        break;
      case "FR":
        downloadLink =
          "https://drive.google.com/file/d/1ewlAVbwOd3_L8FfOFX-SEUAaME744FIZ/view?usp=share_link";
        break;
      case "IT":
        downloadLink =
          "https://drive.google.com/file/d/1rfKMxUEotWjqVhC6qB__oVq0Jgq1f-VS/view?usp=share_link";
        break;
      default:
        break;
    }

    window.open(downloadLink, "_blank");
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <Modal.Header>
        <Modal.Title>{t("welcome-title")} </Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes["modal-body"]}>
        <strong>{t("welcome-subtitle")}</strong> <br />
        <br /> {t("welcome-content-1")} <br />
        <br /> {t("welcome-content-2")} <strong>RUFUS Cloud</strong>!<br />
        <br />
        <Row className={classes["modal-flags"]}>
          <Col>
            <ReactCountryFlag
              countryCode="ES"
              style={{
                fontSize: "2em",
                lineHeight: "0.5em",
              }}
            />
            <br />
            <Button variant="link" onClick={() => handleDownload("ES")}>
              Castellano
            </Button>
          </Col>
          <Col>
            <ReactCountryFlag
              countryCode="BR"
              style={{
                fontSize: "2em",
                lineHeight: "0.5em",
              }}
            />
            <br />
            <Button variant="link" onClick={() => handleDownload("PT")}>
              Português
            </Button>
          </Col>
          <Col>
            <ReactCountryFlag
              countryCode="GB"
              style={{
                fontSize: "2em",
                lineHeight: "0.5em",
              }}
            />
            <br />
            <Button variant="link" onClick={() => handleDownload("EN")}>
              English
            </Button>
          </Col>
          <Col>
            <ReactCountryFlag
              countryCode="IT"
              style={{
                fontSize: "2em",
                lineHeight: "0.5em",
              }}
            />
            <br />
            <Button variant="link" onClick={() => handleDownload("IT")}>
              Italiano
            </Button>
          </Col>
          <Col>
            <ReactCountryFlag
              countryCode="FR"
              style={{
                fontSize: "2em",
                lineHeight: "0.5em",
              }}
            />
            <br />
            <Button variant="link" onClick={() => handleDownload("FR")}>
              Français
            </Button>
          </Col>
        </Row>
        <br />
        <strong>{t("Are you a developer?")} 🤓 </strong>{t("Then you might want to check our")}{" "}
        <a href="https://help.runonrufus.com/public-api" target="_blank" rel="noreferrer">
        {t("public API documentation")}{" "}
        </a>{" "}
        {t("and integrate your developments with the cloud!")}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          {t("Let's go!")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NoDevices;
