import { Fragment, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Amplify } from 'aws-amplify';
import { useTranslation } from "react-i18next";

import { Toaster } from "react-hot-toast";

import Layout from "./components/Layout/Layout";
import AuthPage from "./pages/AuthPage";
import DevicesPage from "./pages/DevicesPage"; //Protected
// import EventsPage from "./pages/EventsPage"; //Protected
import ProfilePage from "./pages/ProfilePage"; //Protected
import WelcomeModal from "./components/UI/WelcomeModal";

import {
  selectCurrentUser,
  selectIsAuthenticated,
  selectIsVerificationPending,
  // selectIsPhoneVerificationPending,
  selectIsChangePasswordPending,
} from "./store/auth-slice";

import { selectPreferredLanguage } from "./store/user-slice";

import { getSession } from "./store/auth-actions";
import { clearUserData } from "./store/user-actions";
import { clearDevicesData } from "./store/devices-actions";
import { clearUI } from "./store/ui-actions";

import awsExports from "./aws-exports";
import EventPage from "./pages/EventPage";

Amplify.configure(awsExports);

const App = () => {
  const { i18n } = useTranslation();
  const preferredLanguage = useSelector(selectPreferredLanguage);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isVerificationPending = useSelector(selectIsVerificationPending);
  // const isPhoneVerificationPending = useSelector(
  //   selectIsPhoneVerificationPending
  // );
  const isChangePasswordPending = useSelector(selectIsChangePasswordPending);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  // console.log("currentUser::::", currentUser)
  // console.log("isAuthenticated:", isAuthenticated)
  // console.log("isVerificationPending:", isVerificationPending)
  // console.log("isPhoneVerificationPending:", isPhoneVerificationPending)

  // console.log("NODE_ENV::::", process.env.NODE_ENV);
  // console.log("REACT_APP_BUILD_ENV::::", process.env.REACT_APP_BUILD_ENV);
  // console.log("REACT_APP_COGNITO_USER_POOL_ID::::", process.env.REACT_APP_COGNITO_USER_POOL_ID);

  useEffect(() => {
    preferredLanguage && i18n.changeLanguage(preferredLanguage);
  }, [preferredLanguage, i18n]);

  useEffect(() => {
    if (isAuthenticated && currentUser) {
      dispatch(getSession());
    } else {
      dispatch(clearUserData());
      dispatch(clearDevicesData());
      dispatch(clearUI());
    }
  }, [isAuthenticated, currentUser, dispatch]);

  return (
    <Fragment>
      <Toaster position="top-right" reverseOrder={false} />
      {!isAuthenticated ? (
        !isVerificationPending ? (
          !isChangePasswordPending ? (
            <Routes>
              <Route path="*" element={<Navigate replace to="/signin" />} />
              <Route path="/signin" element={<AuthPage />} />
              <Route path="/signup" element={<AuthPage />} />
              <Route path="/signup/:invitation" element={<AuthPage />} />
              <Route path="/forgot" element={<AuthPage />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="*" element={<Navigate replace to="/change" />} />
              <Route path="/change" element={<AuthPage />} />
            </Routes>
          )
        ) : (
          <Routes>
            <Route path="*" element={<Navigate replace to="/confirm" />} />
            <Route path="/confirm" element={<AuthPage />} />
          </Routes>
        )
      ) : (
        <Layout>
          <WelcomeModal></WelcomeModal>
          <Routes>
            <Route path="*" element={<Navigate replace to="/devices" />} />
            <Route path="/devices" element={<DevicesPage />} />
            <Route path="/devices/:deviceId" element={<DevicesPage />} />
            <Route
              path="/devices/:deviceId/:sessionId"
              element={<DevicesPage />}
            />
            {/* <Route path="/events" element={<EventsPage />} />
            <Route path="/event/:eventId" element={<EventPage />} /> */}
            <Route path="/profile" element={<ProfilePage />} />
          </Routes>
        </Layout>
      )}
    </Fragment>
  );
};

export default App;
