import { useTranslation } from "react-i18next";

import Table from "react-bootstrap/Table";

import classes from "./PassingsTable.module.css";

import PassingRow from "./PassingRow";

const PassingsTable = (props) => {
  const { t } = useTranslation();
  const passings = props.passings;

  return (
    <Table className={classes.table} striped hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Bib</th>
          <th>Chip</th>
          <th>ID</th>
          <th>Timestamp</th>
          <th>{t("Localization")}</th>
          <th>{t("Status")}</th>
        </tr>
      </thead>
      <tbody>
        {passings.map((passing) => (
          <PassingRow key={passing._id} passing={passing} />
        ))}
      </tbody>
    </Table>
  );
};

export default PassingsTable;
