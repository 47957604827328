import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import * as yup from "yup";

import { resetForm } from "../../store/ui-actions";
import { sendInvitation } from "../../store/user-actions";

import {
  selectCompanyApiToken,
  selectUserApiToken,
} from "../../store/user-slice";
import {
  selectInvitationForm,
  selectButtonSendInvitation,
} from "../../store/ui-slice";

import classes from "./Invitations.module.css";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

const schema = yup
  .object()
  .shape({
    collaboratorEmail: yup
      .string()
      .email(i18n.t("Invalid email address"))
      .required(i18n.t("An email is required")) //.typeError('An email is required typeError'),
      .nullable(),
  })
  .required();

const Invitations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyApiToken = useSelector(selectCompanyApiToken);
  const userApiToken = useSelector(selectUserApiToken);
  const formReseted = useSelector(selectInvitationForm);
  const isSendingInvitation = useSelector(selectButtonSendInvitation);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange", //onSubmit
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (formReseted) {
      reset();
      dispatch(resetForm("invitationForm"));
    }
  }, [formReseted, reset, dispatch]);

  const submitHandler = (data) => {
    const invitation = {
      invitation_email: data.collaboratorEmail,
      company_api_token: companyApiToken,
      inviter_api_token: userApiToken,
      user_type: data.collaboratorUserType,
    };

    dispatch(sendInvitation(invitation));
  };

  const errorHandler = (error) => {
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <h4>{t("Send a new invitation")}</h4>
        <h6>{t("send-new-invitation-subtitle")}</h6>
      </div>
      <Form
        name="invitationForm"
        noValidate
        onSubmit={handleSubmit(submitHandler, errorHandler)}
      >
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCollaboratorEmail">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t("My collaborator email")}
              {...register("collaboratorEmail")}
            />
            {errors.collaboratorEmail && (
              <Form.Text className="text-danger">
                {errors.collaboratorEmail.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCollaboratorType">
            <Form.Label>{t("Type")}</Form.Label>
            <Form.Select
              aria-label={t("Collaborator type")}
              {...register("collaboratorUserType")}
            >
              <option value="READ_ONLY">Read only</option>
              <option value="TIMER">Timer</option>
              <option value="ADMIN">Admin</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <div className={classes.button_container}>
        <Button variant="primary" type="submit" disabled={isSendingInvitation}>
          {isSendingInvitation ? (
            <Spinner as="span" animation="border" size="sm" />
          ) : (
            t("Send invitation")
          )}
        </Button>
        </div>
      </Form>
    </Fragment>
  );
};

export default Invitations;
