import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { generateToken } from "../../store/devices-actions";

import { selectUserApiToken, selectUserType } from "../../store/user-slice";
import { selectTokenDevice } from "../../store/devices-slice";
import { selectButtonGenerateToken } from "../../store/ui-slice";

import { USERTYPES } from "../../store/models/user-types";

import classes from "./GenerateTokenCard.module.css";

import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

const GenerateTokenCard = () => {
  const { t } = useTranslation();
  const userApiToken = useSelector(selectUserApiToken);
  const userType = useSelector(selectUserType);
  const token = useSelector(selectTokenDevice);
  const isLoading = useSelector(selectButtonGenerateToken);
  const dispatch = useDispatch();

  const generateTokenHandler = () => {
    if (userType && userType !== USERTYPES.READ_ONLY) {
      dispatch(generateToken(userApiToken));
    }
  };

  return (
    <Fragment>
      <Col>
        <Card className={classes.card}>
          <Card.Body>
            <Card.Title>{t("Bind a new device")}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {t("bind-device-subtitle")}
            </Card.Subtitle>
            <Card.Text>
              <Button
                variant="primary"
                onClick={generateTokenHandler}
                disabled={isLoading || userType === USERTYPES.READ_ONLY}
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  t("Generate token")
                )}
              </Button>
              <br></br>
              <span>{token.token_device} </span>
            </Card.Text>

            {token.token_until && (
              <Card.Footer>
                <small className="text-muted">
                {t("Valid until")} {token.token_until}
                </small>
              </Card.Footer>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default GenerateTokenCard;
