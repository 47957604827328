export const awsExports = {
  //NOTE: AppSync configuration must be out of the API{} object, if not, subscriptions won't work.
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, //RUFUS Cloud GraphQL Api Api Key
    aws_appsync_region: process.env.REACT_APP_APPSYNC_GRAPHQL_REGION,
    aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTH_TYPE,
    aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY //RUFUS Cloud GraphQL Api Api Key
};

// export const awsExports = {
//   API: {
//     GraphQL: {
//       endpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
//       defaultAuthMode: process.env.REACT_APP_APPSYNC_AUTH_TYPE,
//       apiKey: process.env.REACT_APP_APPSYNC_API_KEY, // Optional
//       region: process.env.REACT_APP_APPSYNC_GRAPHQL_REGION, // Optional
//     },
//   },
// };

export default awsExports;
