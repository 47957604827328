import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Breadcrumb from "react-bootstrap/Breadcrumb";

const Bread = (props) => {
  const { t } = useTranslation();
  const currentDevice = props.currentDevice;
  const currentSession = props.currentSession;

  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/devices" }}>
        {t("Devices")}
      </Breadcrumb.Item>

      {currentDevice.id && (
        <Breadcrumb.Item
          active={!currentSession._id}
          linkAs={Link}
          linkProps={{ to: "/devices/" + currentDevice.id }}
        >
          {currentDevice.alias}
        </Breadcrumb.Item>
      )}

      {(currentSession._id) && (
        <Breadcrumb.Item active>{currentSession.alias}</Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default Bread;
