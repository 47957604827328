import { API, graphqlOperation } from "aws-amplify";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { saveEventPublicationDetails } from "../graphql/mutations";
import ImageUploader from "../components/ImageResize/ImageResize.js";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  Switch,
  TextField,
} from "@mui/material";

import classes from "./EventPage.module.css";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        // backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const EventPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const eventData = state.event;

  const [slug, setSlug] = useState(
    eventData.display_name.replaceAll(" ", "-").toLowerCase()
  );
  console.log(eventData);
  const [validated, setValidated] = useState(false);

  async function saveEvent(event_token, event) {
    try {
      const response = await API.graphql(
        graphqlOperation(saveEventPublicationDetails, {
          event_token: event_token,
          event_details: event,
        })
      );
      navigate("/events");
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());

    console.log(formDataObj);
    if (!e.target.checkValidity()) {
      e.stopPropagation();
      alert("Form is invalid!");
    } else {
      let imageURL =
        "https://rufuscloud-backgrounds.s3.amazonaws.com/" +
        eventData.event_token;

      let eventNewData = {
        active: formDataObj.active ? formDataObj.active === "on" : false,
        display_name: formDataObj.display_name,
        description: formDataObj.description,
        background_image: urlFile ? imageURL + "-0.png" : "",
        background_thumbnail_small: urlFile ? imageURL + "-2.png" : "",
        background_thumbnail_medium: urlFile ? imageURL + +"-1.png" : "",
        event_slug: slug,
        notifications_sms: formDataObj.notifications_sms
          ? formDataObj.notifications_sms === "on"
          : false,
        notifications_email: formDataObj.notifications_email
          ? formDataObj.notifications_email === "on"
          : false,
        notifications_push: formDataObj.notifications_push
          ? formDataObj.notifications_push === "on"
          : false,
      };

      console.log(eventNewData);

      saveEvent(eventData.event_token, eventNewData);

      setValidated(true);
    }
  };

  const onNameChage = (e) => {
    let value = e.target.value;
    setSlug(value.replaceAll(" ", "-").toLowerCase());
    if (value == "") {
      setErrorName("this field is required");
    } else {
      setErrorName(false);
    }
  };

  const [urlFile, setUrlFile] = useState(eventData.background_image ?? "");

  // Form Validate

  const [errorName, setErrorName] = useState(false);

  return (
    <div>
      <div>
        <h1>Event {eventData.name}</h1>
        <p>Bla bla bla </p>
      </div>
      <div>
        <Box component="form" onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  name="active"
                  defaultChecked={eventData.active}
                />
              }
              labelPlacement="start"
              label="Active event"
            />

            <div className={classes["images-container"]}>
              <div className="w-100">
                <img
                  src={
                    urlFile
                      ? `${
                          eventData.background_image
                        }?t=${new Date().getTime()}`
                      : ""
                  }
                  className="w-100"
                  alt="background_image"
                />
              </div>
              {/* <div>
                <img
                  src={
                    urlFile
                      ? `${
                          eventData.background_thumbnail_medium
                        }?t=${new Date().getTime()}`
                      : ""
                  }
                  alt="background_thumbnail_medium"
                  className="w-auto"
                />
              </div>
              <div>
                <img
                  src={
                    urlFile
                      ? `${
                          eventData.background_thumbnail_small
                        }?t=${new Date().getTime()}`
                      : ""
                  }
                  alt="background_thumbnail_small"
                  className="w-auto"
                />
              </div> */}
            </div>
            <div>
              <ImageUploader
                eventToken={eventData.event_token}
                setUrlFile={setUrlFile}
              />
            </div>
            <TextField
              id="name"
              name="display_name"
              label="Name"
              variant="outlined"
              error={!!errorName}
              helperText={errorName}
              required
              defaultValue={eventData.display_name ?? eventData.name}
              onChange={(event) => {
                onNameChage(event);
              }}
            />
            <TextField
              id="description"
              name="description"
              label="Description"
              multiline
              rows={4}
              variant="outlined"
              required
              defaultValue={eventData.description ?? ""}
            />
            <TextField
              id="slug"
              name="event_slug"
              label="Slug"
              variant="outlined"
              disabled
              value={slug}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    name="notifications_sms"
                    defaultChecked={eventData.notifications_sms}
                  />
                }
                labelPlacement="start"
                label="SMS notifications"
              />
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    name="notifications_email"
                    defaultChecked={eventData.notifications_email}
                  />
                }
                labelPlacement="start"
                label="Email notifications"
              />
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    name="notifications_push"
                    defaultChecked={eventData.notifications_push}
                  />
                }
                labelPlacement="start"
                label="Push notifications"
              />
            </FormGroup>
            <div className={classes["form-button-group"]}>
              <Button variant="outlined" type="submit">
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </div>
          </Stack>
        </Box>
      </div>
    </div>
  );
};

export default EventPage;
