import { Fragment, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import * as yup from "yup";

import { updateUserProfile } from "../../store/user-actions";
import {
  changeEmail,
  changePhoneNumber,
  verifyNewEmail,
  verifyNewPhone,
  reSendNewEmailVerificationCode,
  reSendNewPhoneVerificationCode,
} from "../../store/auth-actions";

import {
  selectUserData,
  selectUserContactDetails,
  selectUserPreferences,
  selectUserApiToken,
} from "../../store/user-slice";
import {
  selectIsVerificationPending,
  selectIsPhoneVerificationPending,
} from "../../store/auth-slice";
import {
  selectButtonSaveProfile,
  selectButtonSaveNewEmail,
  selectButtonEmailSendAgain,
  selectButtonConfirmNewEmail,
  selectButtonSaveNewPhone,
  selectButtonPhoneSendAgain,
  selectButtonConfirmNewPhone,
} from "../../store/ui-slice";

import { USERTYPES } from "../../store/models/user-types";
import Logout from "./Logout";
import ChangePassword from "./ChangePassword";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
// import CountrySelect from "react-bootstrap-country-select";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup
  .object()
  .shape(
    {
      company: yup
        .string()
        .nullable()
        .required(i18n.t("A company is required")),
      email: yup
        .string()
        .email(i18n.t("Invalid email address"))
        .required(i18n.t("An email is required")), //.typeError('An email is required typeError'),
      name: yup.string().nullable().required(i18n.t("A name is required")),
      lastname: yup.string().nullable(),
      city: yup.string().nullable(),
      country: yup.string().nullable(),
      countryCode: yup.string(),
      phoneNumber: yup
        .string()
        .nullable()
        .notRequired()
        .when("phoneNumber", {
          is: (value) => value?.length,
          then: (rule) =>
            rule.matches(phoneRegExp, i18n.t("Phone number is not valid")),
        }),
      preferredLanguage: yup.string(),
    },
    [
      // Add Cyclic deps here because when require itself
      ["phoneNumber", "phoneNumber"],
    ]
  )
  .required();

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const userApiToken = useSelector(selectUserApiToken);
  const userContactDetails = useSelector(selectUserContactDetails);
  const userPreferences = useSelector(selectUserPreferences);
  const isVerificationPending = useSelector(selectIsVerificationPending);
  const isPhoneVerificationPending = useSelector(
    selectIsPhoneVerificationPending
  );
  const isSavingProfile = useSelector(selectButtonSaveProfile);
  const isSavingNewEmail = useSelector(selectButtonSaveNewEmail);
  const isSendingAgainEmail = useSelector(selectButtonEmailSendAgain);
  const isConfirmingNewEmail = useSelector(selectButtonConfirmNewEmail);
  const isSavingNewPhone = useSelector(selectButtonSaveNewPhone);
  const isSendingAgainPhone = useSelector(selectButtonPhoneSendAgain);
  const isConfirmingNewPhone = useSelector(selectButtonConfirmNewPhone);
  const [changeEmailState, setChangeEmailState] = useState("change");
  const [emailDisabled, setEmailDisabled] = useState(true);
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [country, setCountry] = useState(userData.country);
  const defaultValues = useMemo(() => {
    return { ...userData, ...userContactDetails, ...userPreferences };
  }, [userData, userContactDetails, userPreferences]);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange", //onSubmit
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(schema),
  });
  const watchEmail = watch("email");
  const watchEmailConfirmCode = watch("emailConfirmCode");
  const watchPhoneConfirmCode = watch("phoneConfirmCode");
  const watchPhoneNumber = watch("phoneNumber");
  const watchCountryCode = watch("countryCode");

  // useEffect(() => {
  //   setCountry(userData.country);
  // }, [userData.country]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    if (!userContactDetails.phoneNumber) setPhoneDisabled(false);
    if (userContactDetails.phoneNumber && isPhoneVerificationPending)
      setPhoneDisabled(true);
    if (userContactDetails.phoneNumber && !isPhoneVerificationPending)
      setPhoneDisabled(false);
  }, [userContactDetails.phoneNumber, isPhoneVerificationPending]);

  useEffect(() => {
    if (changeEmailState === "save") {
      setEmailDisabled(false);
    } else if (changeEmailState === "change") {
      setEmailDisabled(true);
    }

    isVerificationPending && setEmailDisabled(true);
  }, [changeEmailState, isVerificationPending]);

  useEffect(() => {
    isVerificationPending && setEmailDisabled(true);
    !isVerificationPending && setChangeEmailState("change");
  }, [isVerificationPending]);

  const submitHandler = (data) => {
    // data.country = country;

    dispatch(updateUserProfile(data, userApiToken));
  };

  const errorHandler = (error) => {};

  const sendAgainHandler = () => {
    dispatch(reSendNewEmailVerificationCode());
  };

  const sendAgainPhoneHandler = () => {
    dispatch(reSendNewPhoneVerificationCode());
  };

  const changeEmailHandler = () => {
    if (changeEmailState === "change") {
      setChangeEmailState("save");
    }

    if (changeEmailState === "save") {
      if (errors.email) return;

      if (watchEmail !== userContactDetails.email) {
        // Asumo siempre correcto
        dispatch(changeEmail(watchEmail, userApiToken));
      } else {
        setChangeEmailState("change");
      }
    }
  };

  const verifyEmailHandler = () => {
    dispatch(verifyNewEmail(watchEmailConfirmCode));
  };

  const verifyPhoneHandler = () => {
    dispatch(verifyNewPhone(watchPhoneConfirmCode));
  };

  const removePhoneHandler = () => {
    dispatch(changePhoneNumber("", "", userApiToken));
  };

  const changePhoneNumberHandler = () => {
    if (errors.phoneNumber) return;

    dispatch(
      changePhoneNumber(watchCountryCode, watchPhoneNumber, userApiToken)
    );
  };

  const countryChangeHandler = (event) => {
    setCountry(event);
  };

  return (
    <Fragment>
      <Form noValidate onSubmit={handleSubmit(submitHandler, errorHandler)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCompany">
            <Form.Label>
              {userData.userType !== USERTYPES.OWNER
                ? t("This account is managed by:")
                : t("My Company")}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t("My sport business")}
              disabled={userData.userType !== USERTYPES.OWNER}
              {...register("company")}
            />
            {errors.company && (
              <Form.Text className="text-danger">
                {errors.company.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="you@yourcompany.com"
              disabled={emailDisabled}
              {...register("email")}
            />
            {isVerificationPending && (
              <Form.Group as={Col} controlId="formGridEmailConfirmCode">
                <Form.Control
                  type="text"
                  placeholder="123456"
                  {...register("emailConfirmCode")}
                />
                <Button
                  variant="link"
                  onClick={verifyEmailHandler}
                  disabled={isConfirmingNewEmail}
                >
                  {isConfirmingNewEmail ? t("wait...") : t("confirm")}
                </Button>
                <Button
                  variant="link"
                  onClick={sendAgainHandler}
                  disabled={isSendingAgainEmail}
                >
                  {isSendingAgainEmail ? t("sending...") : t("send again")}
                </Button>
              </Form.Group>
            )}
            {!isVerificationPending && (
              <Button
                variant="link"
                onClick={changeEmailHandler}
                disabled={isSavingNewEmail}
              >
                {isSavingNewEmail ? t("saving...") : changeEmailState}
              </Button>
            )}
            {errors.email && (
              <Form.Text className="text-danger">
                {errors.email.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label>{t("Name")}</Form.Label>
            <Form.Control type="text" {...register("name")} />
            {errors.name && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridLastname">
            <Form.Label>{t("Lastname")}</Form.Label>
            <Form.Control type="text" {...register("lastname")} />
            {errors.lastname && (
              <Form.Text className="text-danger">
                {errors.lastname.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>{t("City")}</Form.Label>
            <Form.Control type="text" {...register("city")} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCountry">
            <Form.Label>{t("Country")}</Form.Label>
            <Form.Control type="text" {...register("country")} />
            {/* <CountrySelect
              value={country}
              onChange={countryChangeHandler}
              placeholder={t("Type or select your country")}
              noMatchesText={t("Country not found")}
              valueAs="id"
            /> */}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCountryCode">
            <Form.Label>{t("Country code")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="+34"
              disabled={phoneDisabled}
              {...register("countryCode")}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>{t("Phone number")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="123 456 678"
              disabled={phoneDisabled}
              {...register("phoneNumber")}
            />
            {isPhoneVerificationPending && userContactDetails.phoneNumber && (
              <Form.Group as={Col} controlId="formGridPhoneConfirmCode">
                <Form.Control
                  type="text"
                  placeholder="123456"
                  {...register("phoneConfirmCode")}
                />
                <Button
                  variant="link"
                  onClick={verifyPhoneHandler}
                  disabled={isConfirmingNewPhone}
                >
                  {isConfirmingNewPhone ? t("wait...") : t("confirm")}
                </Button>
                <Button
                  variant="link"
                  onClick={sendAgainPhoneHandler}
                  disabled={isSendingAgainPhone}
                >
                  {isSendingAgainPhone ? t("sending...") : t("send again")}
                </Button>
                <Button
                  variant="link"
                  onClick={removePhoneHandler}
                  disabled={isSavingNewPhone}
                >
                  {isSavingNewPhone ? t("removing...") : t("remove")}
                </Button>
              </Form.Group>
            )}
            {(watchPhoneNumber !== userContactDetails.phoneNumber ||
              watchCountryCode !== userContactDetails.countryCode) && (
              <Button
                variant="link"
                onClick={changePhoneNumberHandler}
                disabled={isSavingNewPhone}
              >
                {isSavingNewPhone ? t("saving...") : t("save")}
              </Button>
            )}
            {errors.phoneNumber && (
              <Form.Text className="text-danger">
                {errors.phoneNumber.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridPreferredLanguage">
            <Form.Label>{t("Preferred language")}</Form.Label>
            <Form.Select
              aria-label="Preferred language"
              {...register("preferredLanguage")}
            >
              <option value="es">Español</option>
              <option value="en">English</option>
              <option value="po">Portugues</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Button variant="primary" type="submit" disabled={isSavingProfile}>
          {isSavingProfile ? (
            <Spinner as="span" animation="border" size="sm" />
          ) : (
            t("Save")
          )}
        </Button>
      </Form>
      <ChangePassword></ChangePassword>
      <Logout></Logout>
    </Fragment>
  );
};

export default Profile;
