import { Fragment } from "react";

import Container from "react-bootstrap/Container";

import Navbar from "./Navbar";

const Layout = (props) => {
  return (
    <Fragment>
      <Navbar />
      <Container>
        <main>{props.children}</main>
      </Container>
    </Fragment>
  );
};

export default Layout;
