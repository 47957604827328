import { createSlice } from "@reduxjs/toolkit";

const initialUiState = {
  forms: {
    invitationForm: false,
    changePasswordForm: false,
    apiKeyForm: false,
  },
  loadings: {
    buttonAuthFormSubmit: false,
    buttonAuthFormSend: false,
    buttonGenerateToken: false,
    buttonSaveProfile: false,
    buttonChangePassword: false,
    buttonSendInvitation: false,
    buttonDeleteInvitation: {},
    buttonDeleteCollaborator: {},
    buttonDeleteApiKey: {},
    buttonSaveNewEmail: false,
    buttonEmailSendAgain: false,
    buttonConfirmNewEmail: false,
    buttonSaveNewPhone: false,
    buttonPhoneSendAgain: false,
    buttonConfirmNewPhone: false,
    buttonCreateApiKey: false,
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialUiState,
  reducers: {
    toggleDynamicLoaders(state, action) {
      const { target, id, status } = action.payload;

      state.loadings[target][id] = status;
    },
    toggleLoaders(state, action) {
      const { target, status } = action.payload;

      state.loadings[target] = status;
    },
    toggleResetForm(state, action) {
      const { form } = action.payload;

      state.forms[form] = !state.forms[form];
    },
    addDynamicButton(state, action) {
      const { target, id } = action.payload;

      state.loadings[target][id] = false;
    },
    removeDynamicButton(state, action) {
      const { target, id } = action.payload;

      delete state.loadings[target][id];
    },
    setDynamicButtons(state, action) {
      const { target, ids } = action.payload;

      ids.map((id) => {
        return (state.loadings[target][id] = false);
      });
    },
    clearUI(state) {
      return initialUiState;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;

export const selectInvitationForm = (state) => state.ui.forms.invitationForm;
export const selectChangePasswordForm = (state) =>
  state.ui.forms.changePasswordForm;
export const selectApiKeyForm = (state) => state.ui.forms.apiKeyForm;

export const selectButtonAuthFormSubmit = (state) =>
  state.ui.loadings.buttonAuthFormSubmit;
export const selectButtonAuthFormSend = (state) =>
  state.ui.loadings.buttonAuthFormSend;
export const selectButtonGenerateToken = (state) =>
  state.ui.loadings.buttonGenerateToken;
export const selectButtonSaveProfile = (state) =>
  state.ui.loadings.buttonSaveProfile;
export const selectButtonChangePassword = (state) =>
  state.ui.loadings.buttonChangePassword;
export const selectButtonSendInvitation = (state) =>
  state.ui.loadings.buttonSendInvitation;
export const selectButtonDeleteInvitation = (state) =>
  state.ui.loadings.buttonDeleteInvitation;
export const selectButtonDeleteCollaborator = (state) =>
  state.ui.loadings.buttonDeleteCollaborator;
export const selectButtonSaveNewEmail = (state) =>
  state.ui.loadings.buttonSaveNewEmail;
export const selectButtonEmailSendAgain = (state) =>
  state.ui.loadings.buttonEmailSendAgain;
export const selectButtonConfirmNewEmail = (state) =>
  state.ui.loadings.buttonConfirmNewEmail;
export const selectButtonSaveNewPhone = (state) =>
  state.ui.loadings.buttonSaveNewPhone;
export const selectButtonPhoneSendAgain = (state) =>
  state.ui.loadings.buttonPhoneSendAgain;
export const selectButtonConfirmNewPhone = (state) =>
  state.ui.loadings.buttonConfirmNewPhone;
export const selectButtonCreateApiKey = (state) =>
  state.ui.loadings.buttonCreateApiKey;
export const selectButtonDeleteApiKey = (state) =>
  state.ui.loadings.buttonDeleteApiKey;
