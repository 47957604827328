import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import { Trash3Fill } from "react-bootstrap-icons";

import {
  getPendingInvitations,
  deleteInvitation,
} from "../../store/user-actions";

import {
  selectPendingInvitationsList,
  selectUserApiToken,
} from "../../store/user-slice";
import { selectButtonDeleteInvitation } from "../../store/ui-slice";

import classes from "./InvitationsList.module.css";

import { PeopleFill } from "react-bootstrap-icons";

const InvitationsList = () => {
  const { t } = useTranslation();
  const [hoover, setHoover] = useState(null);
  const pendingInvitationsList = useSelector(selectPendingInvitationsList);
  const isLoading = useSelector(selectButtonDeleteInvitation);
  const userApiToken = useSelector(selectUserApiToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userApiToken) {
      dispatch(getPendingInvitations(userApiToken));
    }
  }, [dispatch, userApiToken]);

  const removeHandler = (hash) => {
    dispatch(deleteInvitation(hash));
  };

  const onMouseEnterHandler = (button) => {
    setHoover(button);
  };

  const onMouseLeaveHandler = (button) => {
    setHoover(null);
  };

  return (
    <div className={classes["list-container"]}>
      {pendingInvitationsList.length > 0 && <h5>{t("Pending invitations")}</h5>}
      <ListGroup>
        {pendingInvitationsList.map((invitation) => (
          <ListGroup.Item
            key={invitation.hash}
            onMouseEnter={() => onMouseEnterHandler(invitation.hash)}
            onMouseLeave={() => onMouseLeaveHandler(invitation.hash)}
          >
            <PeopleFill /> {invitation.invitation_email}{" "}
            <Badge bg="info">{invitation.user_type}</Badge>{" "}
            <Trash3Fill
              onClick={() => removeHandler(invitation.hash)}
              disabled={isLoading[invitation.hash]}
              display={hoover === invitation.hash ? "inline" : "none"}
              className={classes["trash"]}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default InvitationsList;
