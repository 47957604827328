import { useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

import { selectPassingsList } from "../../store/devices-slice";

import classes from "./AllPassings.module.css";

import { CloudDownload } from "react-bootstrap-icons";
import Badge from "react-bootstrap/Badge";

import PassingsTable from "./PassingsTable";
import NoPassings from "./NoPassings";

const AllSessions = (props) => {
  const { t } = useTranslation();
  const [csvPassings, setCsvPassings] = useState([]);
  const passings = useSelector(selectPassingsList);
  const currentSession = props.currentSession || {};
  const numOfPassings = passings ? passings.length : 0;
  const csvFileName =
    currentSession && currentSession.alias
      ? currentSession.alias.includes(".csv")
        ? currentSession.alias
        : currentSession.alias.replace(".csv", "")
      : "current_session.csv";

  const downloadHandler = (event, done) => {
    if (passings && passings.length > 0) {
      const csvData = passings.map(
        ({ _id, token_session, event_token, ...rest }) => {
          return rest;
        }
      );
      setCsvPassings(csvData);
    }
    done();
  };

  return (
    <>
      <div className={classes.edit}>
        <h1>{t("Passings from")} {currentSession.alias}</h1>{" "}
        <h6>{currentSession.active && <Badge bg="danger">ACTIVE</Badge>}</h6>
      </div>
      <span>
        {t("There are")} {numOfPassings} {t("passings availables for the current session.")}{" "}
        {numOfPassings > 0 && (
          <CSVLink
            filename={csvFileName}
            data={csvPassings}
            asyncOnClick={true}
            onClick={downloadHandler}
          >
            {t("Download CSV file")} <CloudDownload />
          </CSVLink>
        )}
      </span>
      {passings && passings.length > 0 && <PassingsTable passings={passings} />}
      {passings && passings.length === 0 && <NoPassings/>}
    </>
  );
};

export default AllSessions;
