import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";
import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import {
  signIn,
  signUp,
  verifyEmail,
  resetPassword,
  sendRecoveryToken,
  reSendVerificationCode,
} from "../../store/auth-actions";

import {
  selectButtonAuthFormSubmit,
  selectButtonAuthFormSend,
} from "../../store/ui-slice";

import classes from "./AuthForm.module.css";

YupPassword(yup);
const schemaSign = yup
  .object()
  .shape({
    email: yup
      .string()
      .nullable()
      .email("Invalid email address")
      .required("An email is required"),
    password: yup
      .string()
      .nullable()
      .required("A password is required")
      .min(8, "Password is too short (8 chars min)")
      .minLowercase(1, "Must contain at least 1 lower case letter")
      .minUppercase(1, "Must contain at least 1 upper case letter")
      .minNumbers(1, "Must contain at least 1 number"),
  })
  .required();

const schemaForgot = yup
  .object()
  .shape({
    email: yup
      .string()
      .nullable()
      .email("Invalid email address")
      .required("An email is required"),
  })
  .required();

const schemaChange = yup
  .object()
  .shape({
    password: yup
      .string()
      .nullable()
      .required("A password is required")
      .min(8, "Password is too short (8 chars min)")
      .minLowercase(1, "Must contain at least 1 lower case letter")
      .minUppercase(1, "Must contain at least 1 upper case letter")
      .minNumbers(1, "Must contain at least 1 number"),
    code: yup.string().nullable().required("A code is required"),
  })
  .required();

const schemaConfirm = yup
  .object()
  .shape({
    code: yup.string().nullable().required("A code is required"),
  })
  .required();

const AuthForm = () => {
  const { t } = useTranslation();
  const match = useLocation();
  const navigate = useNavigate();
  const formState = match.pathname;
  const dispatch = useDispatch();
  const params = useParams();
  const isLoading = useSelector(selectButtonAuthFormSubmit);
  const isSending = useSelector(selectButtonAuthFormSend);
  const { invitation } = params;
  const dynamicValidationSchema = () => {
    if (formState.includes("/sign")) return schemaSign;
    if (formState.includes("/forgot")) return schemaForgot;
    if (formState.includes("/change")) return schemaChange;
    if (formState.includes("/confirm")) return schemaConfirm;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange", //onSbmit
    resolver: yupResolver(dynamicValidationSchema()),
  });

  let isSignIn = true;
  let isSignUp = false;
  let isForgot = false;
  let isConfirm = false;
  let isChange = false;
  let formTitle = t("Sign in to RUFUS Cloud");
  let confirmEmail = "";

  if (formState.includes("/signup")) {
    formTitle = t("Create your RUFUS Cloud account");
    isSignIn = false;
    isSignUp = true;
    isForgot = false;
    isConfirm = false;
    isChange = false;
  }

  if (formState === "/forgot") {
    formTitle = t("Forgot password?");
    isSignIn = false;
    isSignUp = false;
    isForgot = true;
    isConfirm = false;
    isChange = false;
  }

  if (formState === "/confirm") {
    formTitle = t("Confirm your email address");
    isSignIn = false;
    isSignUp = false;
    isForgot = false;
    isConfirm = true;
    isChange = false;

    confirmEmail = localStorage.getItem("confirmation-email");
  }

  if (formState === "/change") {
    formTitle = t("Change your password");
    isSignIn = false;
    isSignUp = false;
    isForgot = false;
    isConfirm = false;
    isChange = true;

    confirmEmail = localStorage.getItem("confirmation-email");
  }

  const signInClickHandler = () => {
    clearErrors();
    navigate("/signin");
  };

  const forgotClickHandler = () => {
    clearErrors();
    navigate("/forgot");
  };

  const signUpClickHandler = () => {
    clearErrors();
    navigate("/signup");
  };

  const sendAgainHandler = () => {
    const enteredEmail = confirmEmail;

    if (isConfirm) {
      dispatch(reSendVerificationCode(enteredEmail));
    }

    if (isChange) {
      dispatch(sendRecoveryToken(enteredEmail));
    }
  };

  const submitHandler = (data) => {
    const enteredEmail = data.email || confirmEmail;
    const enteredPassword = data.password;
    const enteredCode = data.code;

    if (isSignIn) {
      dispatch(signIn(enteredEmail, enteredPassword));
    }

    if (isSignUp) {
      dispatch(
        signUp(enteredEmail, enteredPassword, invitation ? invitation : null)
      );
    }

    if (isConfirm) {
      dispatch(verifyEmail(enteredEmail, enteredCode));
    }

    if (isForgot) {
      dispatch(sendRecoveryToken(enteredEmail));
    }

    if (isChange) {
      dispatch(resetPassword(enteredEmail, enteredPassword, enteredCode));
    }
  };

  const errorHandler = (error) => {
  };

  return (
    <Form
      className={classes.form}
      noValidate
      onSubmit={handleSubmit(submitHandler, errorHandler)}
    >
      <h3 className="mb-3">{formTitle}</h3>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Control
          type="email"
          placeholder={t("Enter your email address")}
          defaultValue={confirmEmail}
          disabled={isConfirm || isChange}
          {...register("email")}
        />

        {errors.email && (
          <>
            <Form.Text className="text-danger">
              {errors.email.message}
            </Form.Text>
            <br />
          </>
        )}

        {isSignUp && (
          <Form.Text muted>
            {t("We'll never share your email with anyone else.")}
          </Form.Text>
        )}
      </Form.Group>

      {(isChange || isConfirm) && (
        <Form.Group className="mb-3" controlId="formChangePasswordCode">
          <Form.Control
            type="text"
            placeholder={isConfirm ? t("Confirmation code") : t("Reset code")}
            {...register("code")}
          />
          {errors.code && (
            <>
              <Form.Text className="text-danger">
                {errors.code.message}
              </Form.Text>
              <br />
            </>
          )}
        </Form.Group>
      )}

      {!isForgot && !isConfirm && (
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Control
            type={isConfirm ? "text" : "password"}
            placeholder={
              !isChange ? t("Enter your password") : t("Enter new password")
            }
            {...register("password")}
          />
          {errors.password && (
            <Form.Text className="text-danger">
              {errors.password.message}
            </Form.Text>
          )}
        </Form.Group>
      )}

      <Button variant="primary" type="submit" disabled={isLoading}>
        {isLoading ? (
          <Spinner as="span" animation="border" size="sm" />
        ) : isSignUp ? (
          t("Create new account")
        ) : isForgot ? (
          t("Send recovery token")
        ) : isConfirm ? (
          t("Confirm")
        ) : isChange ? (
          t("Change password")
        ) : (
          t("Sign in")
        )}
      </Button>

      {isSignIn && (
        <Row>
          <Col>
            {t("Not a member?")}
            <Button variant="link" onClick={signUpClickHandler}>
              {t("Register")}
            </Button>
          </Col>
          <Col>
            <Button variant="link" onClick={forgotClickHandler}>
              {t("Forgot password?")}
            </Button>
          </Col>
        </Row>
      )}

      {isSignUp && (
        <Row>
          <Col>
            {t("Already a member?")}{" "}
            <Button as="a" variant="link" onClick={signInClickHandler}>
            {t("Sign in")}
            </Button>
          </Col>
          <Col>
            {t("By clicking Sign up you agree to our")}{" "}
            <a
              href="https://www.runonrufus.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              {t("terms of service")}
            </a>
          </Col>
        </Row>
      )}

      {isForgot && (
        <Row>
          {t("Suddenly remember?")}
          <Button variant="link" onClick={signInClickHandler}>
          {t("Sign in")}
          </Button>
        </Row>
      )}

      {(isConfirm || isChange) && (
        <Row>
          {isConfirm ? t("Didn't receive a code?") : t("Didn't receive the token?")}
          <Button
            variant="link"
            onClick={sendAgainHandler}
            disabled={isSending}
          >
            {isSending ? t("Sending...") : t("Send again")}
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default AuthForm;
