import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import { DoorOpen } from "react-bootstrap-icons";

import classes from "./Logout.module.css";

import { signOut } from "../../store/auth-actions";

const Logout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(signOut());
  };

  return (
    <div className={classes.right}>
      <Button variant="link" onClick={logoutHandler}>
      {t("Logout")} <DoorOpen/>
      </Button>
    </div>
  );
};

export default Logout;
