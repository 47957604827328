import { uiActions } from "./ui-slice";

export const removeDynamicButton = (target, id) => {
  return async (dispatch) => {
    dispatch(
      uiActions.removeDynamicButton({
        target: target,
        id: id,
      })
    );
  };
};

export const addDynamicButton = (target, id) => {
  return async (dispatch) => {
    dispatch(
      uiActions.addDynamicButton({
        target: target,
        id: id,
      })
    );
  };
};

export const setDynamicButtons = (target, ids) => {
  return async (dispatch) => {
    dispatch(
      uiActions.setDynamicButtons({
        target: target,
        ids: ids,
      })
    );
  };
};

export const setDynamicLoading = (target, id, status) => {
  return async (dispatch) => {
    dispatch(
      uiActions.toggleDynamicLoaders({
        target: target,
        id: id,
        status: status,
      })
    );
  };
};

export const setLoading = (target, status) => {
  return async (dispatch) => {
    dispatch(
      uiActions.toggleLoaders({
        target: target,
        status: status,
      })
    );
  };
};

export const resetForm = (form) => {
  return async (dispatch) => {
    dispatch(
      uiActions.toggleResetForm({
        form: form,
      })
    );
  };
};

export const clearUI = () => {
  return async (dispatch) => {
    dispatch(uiActions.clearUI());
  };
};