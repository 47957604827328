import { API, graphqlOperation } from "aws-amplify";
// import { GraphQLAPI as API, graphqlOperation } from '@aws-amplify/api-graphql';

import {
  getDevicesByCompany,
  getDeviceSessions,
  getSessionPassingsById,
} from "../graphql/queries";
import { generateTokenDevice, changeDeviceAlias } from "../graphql/mutations";

import { setLoading } from "./ui-actions";

import { devicesActions } from "./devices-slice";
import { ToastSuccess, ToastError } from "../components/Helpers/ToastHelper";

export const getPassingsList = (sessionId) => {
  return async (dispatch) => {
    try {
      //TODO: Cambiar este dispatch que resetea el array por loader.
      dispatch(
        devicesActions.setPassings({
          passings: null,
        })
      );

      const response = await API.graphql(
        graphqlOperation(getSessionPassingsById, {
          session_id: sessionId,
        })
      );
      const passings = response.data.getSessionPassingsById;

      dispatch(
        devicesActions.setPassings({
          passings,
        })
      );
    } catch (error) {
      ToastError(error);
    }
  };
};

export const getSessionsList = (companyApiToken, deviceId) => {
  return async (dispatch) => {
    try {
      //TODO: Cambiar este dispatch que resetea el array por loader.
      dispatch(
        devicesActions.setSessions({
          sessions: null,
        })
      );

      const response = await API.graphql(
        graphqlOperation(getDeviceSessions, {
          company_api_token: companyApiToken,
          device_id: deviceId,
        })
      );
      const sessions = response.data.getDeviceSessions;

      dispatch(
        devicesActions.setSessions({
          sessions,
        })
      );
    } catch (error) {
      ToastError(error);
    }
  };
};

export const getDevicesList = (companyApiToken) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(getDevicesByCompany, {
          company_api_token: companyApiToken,
        })
      );
      const devices = response.data.getDevicesByCompany;

      dispatch(
        devicesActions.setDevices({
          devices,
        })
      );
    } catch (error) {
      ToastError(error);
    }
  };
};

export const setDeviceAlias = (companyApiToken, deviceId, newAlias) => {
  return async (dispatch) => {
    try {
      //Primero actualizamos el state para evitar el parpadeo
      dispatch(
        devicesActions.setDeviceAlias({
          deviceId,
          newAlias,
        })
      );

      const response = await API.graphql(
        graphqlOperation(changeDeviceAlias, {
          company_api_token: companyApiToken,
          device_id: deviceId,
          new_alias: newAlias,
        })
      );
      const result = response.data.changeDeviceAlias;

      ToastSuccess("DeviceAliasUpdated");
    } catch (error) {
      ToastError(error);
    }
  };
};

export const generateToken = (userApiToken) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonGenerateToken", true));

      const response = await API.graphql(
        graphqlOperation(generateTokenDevice, {
          api_token: userApiToken,
        })
      );
      const token = response.data.generateTokenDevice;

      ToastSuccess("NewTokenGenerated", { token: token.token_device });

      dispatch(
        devicesActions.setTokenDevice({
          token,
        })
      );
    } catch (error) {
      ToastError(error);
    } finally {
      dispatch(setLoading("buttonGenerateToken", false));
    }
  };
};

export const clearDevicesData = () => {
  return async (dispatch) => {
    dispatch(devicesActions.clearDevices());
  };
};
