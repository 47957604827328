import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import classes from "./ProfileTabs.module.css";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { selectUserType } from "../../store/user-slice";
import { USERTYPES } from "../../store/models/user-types";

import Profile from "./Profile";
import Invitations from "./Invitations";
import InvitationsList from "./InvitationsList";
import CollaboratorsList from "./CollaboratorsList";
import ApiKeys from "./ApiKeys";
import ApiKeysTable from "./ApiKeysTable";
import TabHeader from "./TabHeader";

const ProfileTabs = () => {
  const { t } = useTranslation();
  const userType = useSelector(selectUserType);

  const tabClass = `mb-3 ${classes.tabs}`;

  return (
    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className={tabClass}
    >
      <Tab eventKey="profile" title={t("Profile")}>
        <TabHeader activeTab="profile" />
        <Profile />
      </Tab>
      <Tab eventKey="team" title={t("Team")}>
        <TabHeader activeTab="team" />
        {(userType === USERTYPES.ADMIN || userType === USERTYPES.OWNER) && (
          <>
            <Invitations />
            <InvitationsList />
          </>
        )}
        <CollaboratorsList />
      </Tab>
      <Tab eventKey="api-keys" title={t("Api keys")}>
        <TabHeader activeTab="api-keys" />
        {(userType === USERTYPES.ADMIN || userType === USERTYPES.OWNER) && (
          <ApiKeys />
        )}
        <ApiKeysTable />
      </Tab>
    </Tabs>
  );
};

export default ProfileTabs;
