import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import { PersonCheckFill, Trash3Fill } from "react-bootstrap-icons";

import {
  getCollaboratorsList,
  deleteCollaborator,
  updateCollaboratorUserType,
} from "../../store/user-actions";

import {
  selectCollaboratorsList,
  selectCompanyApiToken,
  selectUserApiToken,
  selectUserType,
} from "../../store/user-slice";
import { selectButtonDeleteCollaborator } from "../../store/ui-slice";

import { USERTYPES } from "../../store/models/user-types";

import classes from "./CollaboratorsList.module.css";

const CollaboratorsList = () => {
  const { t } = useTranslation();
  const [hoover, setHoover] = useState(null);
  const collaboratorsList = useSelector(selectCollaboratorsList);
  const companyApiToken = useSelector(selectCompanyApiToken);
  const userApiToken = useSelector(selectUserApiToken);
  const userType = useSelector(selectUserType);
  const isLoading = useSelector(selectButtonDeleteCollaborator);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyApiToken) {
      dispatch(getCollaboratorsList(companyApiToken));
    }
  }, [dispatch, companyApiToken]);

  const changeTypeHandler = (id, userType) => {
    dispatch(updateCollaboratorUserType(id, userType));
  };

  const removeHandler = (hash) => {
    dispatch(deleteCollaborator(hash));
  };

  const onMouseEnterHandler = (button) => {
    setHoover(button);
  };

  const onMouseLeaveHandler = (button) => {
    setHoover(null);
  };

  return (
    <div className={classes["list-container"]}>
      {collaboratorsList.length > 1 && <h5>{t("Collaborators")}</h5>}
      <ListGroup>
        {collaboratorsList.map(
          (collaborator) =>
            collaborator.collaborator_api_token !== userApiToken && (
              <ListGroup.Item
                key={collaborator.id}
                onMouseEnter={() => onMouseEnterHandler(collaborator.id)}
                onMouseLeave={() => onMouseLeaveHandler(collaborator.id)}
              >
                <PersonCheckFill /> {collaborator.collaborator_email}{" "}
                {userType !== USERTYPES.ADMIN &&
                  userType !== USERTYPES.OWNER && (
                    <Badge bg="info">{collaborator.user_type}</Badge>
                  )}{" "}
                {(userType === USERTYPES.ADMIN ||
                  userType === USERTYPES.OWNER) &&
                  collaborator.user_type !== USERTYPES.OWNER && (
                    <>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={collaborator.user_type}
                        as="span"
                        size="sm"
                      >
                        <Dropdown.Item
                          as="button"
                          onClick={() =>
                            changeTypeHandler(
                              collaborator.id,
                              USERTYPES.READ_ONLY
                            )
                          }
                        >
                          Read only
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() =>
                            changeTypeHandler(collaborator.id, USERTYPES.TIMER)
                          }
                        >
                          Timer
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() =>
                            changeTypeHandler(collaborator.id, USERTYPES.ADMIN)
                          }
                        >
                          Admin
                        </Dropdown.Item>
                      </DropdownButton>{" "}
                      <Trash3Fill
                        onClick={() => removeHandler(collaborator.id)}
                        disabled={isLoading[collaborator.id]}
                        display={hoover === collaborator.id ? "inline" : "none"}
                        className={classes["trash"]}
                      />
                      {/* <Button
                        variant="link"
                        onClick={() => removeHandler(collaborator.id)}
                        disabled={isDeletingCollaborator[collaborator.id]}
                      >
                        {isDeletingCollaborator[collaborator.id]
                          ? "removing..."
                          : "remove"}
                      </Button> */}
                    </>
                  )}
              </ListGroup.Item>
            )
        )}
      </ListGroup>
    </div>
  );
};

export default CollaboratorsList;
