import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import {
  selectUserData,
  selectUserContactDetails,
} from "../../store/user-slice";

// Assets
import rufusLogo from "../../assets/logo-rufus-navbar.svg";

const NavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const userContactDetails = useSelector(selectUserContactDetails);

  const devicesClickHandler = () => {
    navigate("/devices");
  };

  const eventsClickHandler = () => {
    window.open("https://rm.runonrufus.com/", "_blank");
    return null;
  };

  const profileClickHandler = () => {
    navigate("/profile");
  };

  const eventAdminClickHandler = () => {
    navigate("/events");
  };

  const apiDocsClickHandler = () => {
    window.open("https://help.runonrufus.com/public-api", "_blank");
    return null;
  };

  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>
        <Navbar.Brand onClick={devicesClickHandler}>
          <img
            src={rufusLogo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="RUFUS beta logo"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={devicesClickHandler}>{t("Devices")}</Nav.Link>
            <Nav.Link onClick={eventsClickHandler}>
              Race Manager Web (beta)
            </Nav.Link>
            <Nav.Link onClick={apiDocsClickHandler}>🤓 Api docs</Nav.Link>
            {/* <Nav.Link onClick={eventAdminClickHandler}>Event admin</Nav.Link> */}
          </Nav>
          <Navbar.Text className="justify-content-end">
            {t("Signed in as:")}{" "}
            <a onClick={profileClickHandler} href="#profile">
              {userData.company
                ? userData.company
                : userData.name
                ? userData.name
                : userContactDetails.email}
            </a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
