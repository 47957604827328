import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import classes from "./AllDevices.module.css";

import { selectDevicesList } from "../../store/devices-slice";

import DevicesList from "./DevicesList";
import NoDevices from "./NoDevices";

const AllDevices = (props) => {
  const { t } = useTranslation();
  const devices = useSelector(selectDevicesList);

  return (
    <>
      <div className={classes.header}>
        <h1>{t("Devices list")}</h1>
        <h6>
          {t("devices-subtitle")}
        </h6>
      </div>
      {devices && devices.length > 0 && (
        <>
          <DevicesList devices={devices} />
        </>
      )}
      {devices && devices.length === 0 && <NoDevices />}
    </>
  );
};

export default AllDevices;
