import { createSlice } from "@reduxjs/toolkit";

const initialDevicesState = {
  devicesList: null,
  sessionsList: null,
  passingsList: null,
  tokenDevice: {},
};

const devicesSlice = createSlice({
  name: "devices",
  initialState: initialDevicesState,
  reducers: {
    setDeviceAlias(state, action) {
      const { deviceId, newAlias } = action.payload;

      state.devicesList = state.devicesList.filter((device) => {
        if (device.id === deviceId) {
          return (device.alias = newAlias);
        }
        return device;
      });
    },
    setTokenDevice(state, action) {
      const { token } = action.payload;

      state.tokenDevice = token;
    },
    setDevices(state, action) {
      const { devices } = action.payload;

      state.devicesList = devices;
    },
    setSessions(state, action) {
      const { sessions } = action.payload;

      state.sessionsList = sessions;
    },
    setPassings(state, action) {
      const { passings } = action.payload;

      state.passingsList = passings;
    },
    clearDevices(state) {
      return initialDevicesState;
    },
  },
});

export const devicesActions = devicesSlice.actions;

export default devicesSlice.reducer;

export const selectDevicesList = (state) => state.devices.devicesList;
export const selectSessionsList = (state) => state.devices.sessionsList;
export const selectPassingsList = (state) => state.devices.passingsList;
export const selectTokenDevice = (state) => state.devices.tokenDevice;
