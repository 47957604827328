import Container from "react-bootstrap/Container";

import classes from "./ProfilePage.module.css";

import ProfileTabs from "../components/Profile/ProfileTabs";
import ProfileHeader from "../components/Profile/ProfileHeader";

const ProfilePage = () => {
  return (
    <>
      <Container className={classes["tab-container"]}>
        <ProfileHeader />
        <ProfileTabs />
      </Container>
    </>
  );
};

export default ProfilePage;
