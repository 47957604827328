import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  companyApiToken: null,
  userApiToken: null,
  data: {
    userType: "READ_ONLY",
    company: null,
    name: null,
    lastname: null,
    city: null,
    country: null,
  },
  contactDetails: {
    email: null,
    countryCode: null,
    phoneNumber: null,
    phoneValidated: false,
  },
  preferences: {
    preferredLanguage: null,
  },
  onboarding: {
    welcomeMessageSeen: true,
  },
  apiKeys: [],
  pendingInvitationsList: [],
  collaboratorsList: [],
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    removeApiKey(state, action) {
      const apiKey = action.payload.apiKey;

      state.apiKeys = state.apiKeys.filter(
        (api_key) => api_key.api_key !== apiKey
      );
    },
    setApiKeys(state, action) {
      const apiKeys = action.payload.apiKeys;

      state.apiKeys = apiKeys;
    },
    addApiKey(state, action) {
      const { api_key, alias, access_type, allow_unbind } = action.payload;

      state.apiKeys.push({
        api_key,
        alias,
        access_type,
        allow_unbind,
      });
    },
    setOnboarding(state, action) {
      const { target, status } = action.payload;

      state.onboarding[target] = status;
    },
    updateCollaboratorUserType(state, action) {
      const id = action.payload.id;
      const userType = action.payload.userType;

      state.collaboratorsList = state.collaboratorsList.filter(
        (collaborator) => {
          if (collaborator.id === id) {
            return (collaborator.user_type = userType);
          }
          return collaborator;
        }
      );
    },
    removeCollaborator(state, action) {
      const id = action.payload.id;

      state.collaboratorsList = state.collaboratorsList.filter(
        (collaborator) => collaborator.id !== id
      );
    },
    setCollaborators(state, action) {
      const collaborators = action.payload.collaborators;

      state.collaboratorsList = collaborators;
    },
    removePendingInvitations(state, action) {
      const hash = action.payload.hash;

      state.pendingInvitationsList = state.pendingInvitationsList.filter(
        (invitation) => invitation.hash !== hash
      );
    },
    setPendingInvitations(state, action) {
      const invitations = action.payload.invitations;

      state.pendingInvitationsList = invitations;
    },
    addPendingInvitation(state, action) {
      const invitation = action.payload.newInvitation;

      state.pendingInvitationsList.push(invitation);
    },
    setUser(state, action) {
      const user = action.payload.user;

      state.data = {
        userType: user.user_type,
        company: user.company,
        name: user.name,
        lastname: user.lastname,
        city: user.city,
        country: user.country,
      };

      state.contactDetails = {
        email: user.email,
        countryCode: user.country_code || "",
        phoneNumber: user.telephone || "",
      };

      state.preferences = {
        preferredLanguage: user.preferred_language,
      };

      state.onboarding = {
        welcomeMessageSeen: false,
      };

      state.companyApiToken = user.company_api_token;
      state.userApiToken = user.api_token;
    },
    updateUser(state, action) {
      const user = action.payload.updatedUser;

      state.data.company = user.company;
      state.data.name = user.name;
      state.data.lastname = user.lastname;
      state.data.city = user.city;
      state.data.country = user.country;

      state.preferences.preferredLanguage = user.preferred_language;
    },
    updateUserEmail(state, action) {
      const user = action.payload.updatedUser;

      state.contactDetails.email = user.email;
    },
    updateUserPhone(state, action) {
      const user = action.payload.updatedUser;

      state.contactDetails.countryCode = user.country_code;
      state.contactDetails.phoneNumber = user.telephone;
    },
    clearUser(state) {
      return initialUserState;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;

export const selectCompanyApiToken = (state) => state.user.companyApiToken;
export const selectUserApiToken = (state) => state.user.userApiToken;
export const selectUserType = (state) => state.user.data.userType;
export const selectUserData = (state) => state.user.data;
export const selectUserContactDetails = (state) => state.user.contactDetails;
export const selectUserPreferences = (state) => state.user.preferences;
export const selectPreferredLanguage = (state) => state.user.preferences.preferredLanguage;
export const selectCollaboratorsList = (state) => state.user.collaboratorsList;
export const selectOnboarding = (state) => state.user.onboarding;
export const selectPendingInvitationsList = (state) =>
  state.user.pendingInvitationsList;
export const selectApiKeys = (state) => state.user.apiKeys;
