import { useTranslation } from "react-i18next";

import classes from "./NoSessions.module.css";

import Image from "react-bootstrap/Image";
import imgNoSessions from "../../assets/no-sessions.png";


const NoSessions = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes["no-sessions"]}>
        <span>
          <b>{t("This is an empty device!")}</b>
        </span>
        <Image src={imgNoSessions} />
        <span>
          {t("empty-device-info")}
        </span>
      </div>
    </div>
  );
};

export default NoSessions;
