import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth-slice";
import uiReducer from "./ui-slice";
import userReducer from "./user-slice";
import devicesReducer from "./devices-slice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    user: userReducer,
    devices: devicesReducer,
  },
});

export default store;
