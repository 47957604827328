import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import * as yup from "yup";

import { resetForm } from "../../store/ui-actions";
import { createNewApiKey } from "../../store/user-actions";

import { selectCompanyApiToken, selectApiKeys } from "../../store/user-slice";
import {
  selectApiKeyForm,
  selectButtonCreateApiKey,
} from "../../store/ui-slice";

import classes from "./ApiKeys.module.css";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import { ToastInfo } from "../Helpers/ToastHelper";

const schema = yup
  .object()
  .shape({
    apiAlias: yup.string().required(i18n.t("An alias is required")).nullable(),
  })
  .required();

const ApiKeys = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiAccessTypeRef = useRef();
  const [allowUnbindEnabled, setAllowUnbindEnabled] = useState(false);
  const [allowUnbind, setAllowUnbind] = useState(false);
  const apiKeysList = useSelector(selectApiKeys);
  const companyApiToken = useSelector(selectCompanyApiToken);
  const formReseted = useSelector(selectApiKeyForm);
  const isLoading = useSelector(selectButtonCreateApiKey);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange", //onSubmit
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (formReseted) {
      reset();
      setAllowUnbind(false);
      setAllowUnbindEnabled(false);
      dispatch(resetForm("apiKeyForm"));
    }
  }, [formReseted, reset, dispatch]);

  const submitHandler = (data) => {
    const apiAlias = data.apiAlias;
    const apiAccessType = apiAccessTypeRef.current.value;
    const apiAllowUnbind = allowUnbind;

    if (apiKeysList?.length >= 10) {
      ToastInfo("ApiKeyMaximumReached");
      return;
    }

    dispatch(
      createNewApiKey(companyApiToken, apiAlias, apiAccessType, apiAllowUnbind)
    );
  };

  const onChangeHandler = (event) => {
    const selectedPermission = apiAccessTypeRef.current.value;

    if (selectedPermission === "WRITE" || selectedPermission === "READ_WRITE") {
      setAllowUnbindEnabled(true);
    } else {
      setAllowUnbind(false);
      setAllowUnbindEnabled(false);
    }
  };

  const checkHandler = () => {
    setAllowUnbind(!allowUnbind);
  };

  const errorHandler = (error) => {
    console.log("FormERROR:::", error);
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <h4>{t("Create a new api key")}</h4>
        <h6>{t("create-api-key-subtitle")}</h6>
      </div>
      <Form
        name="apiKeyForm"
        noValidate
        onSubmit={handleSubmit(submitHandler, errorHandler)}
      >
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridApiAlias">
            <Form.Label>{t("Alias")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("My api key alias")}
              {...register("apiAlias")}
            />
            {errors.apiAlias && (
              <Form.Text className="text-danger">
                {errors.apiAlias.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridApiAccessType">
            <Form.Label>{t("Access type")}</Form.Label>
            <Form.Select
              aria-label="Access type"
              onChange={onChangeHandler}
              ref={apiAccessTypeRef}
            >
              <option value="READ">Read only</option>
              <option value="WRITE">Write only</option>
              <option value="READ_WRITE">Read and write</option>
            </Form.Select>
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="formGridAllowUnbind"
            className={classes["checkbox-container"]}
          >
            <Form.Label as="div" disabled={!allowUnbindEnabled}>
              {t("Unbind")}
            </Form.Label>
            <Form.Check
              type="switch"
              id="custom-switch"
              label={t("Allow unbind device")}
              disabled={!allowUnbindEnabled}
              checked={allowUnbind}
              onChange={checkHandler}
            />
          </Form.Group>
        </Row>
        <div className={classes["button-container"]}>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? (
              <Spinner as="span" animation="border" size="sm" />
            ) : (
              t("Create api key")
            )}
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};

export default ApiKeys;
