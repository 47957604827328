import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { Trash3Fill } from "react-bootstrap-icons";

import { selectUserType, selectCompanyApiToken } from "../../store/user-slice";
import { selectButtonDeleteApiKey } from "../../store/ui-slice";

import { deleteApiKey } from "../../store/user-actions";

import classes from "./ApiKeyRow.module.css";

import { USERTYPES } from "../../store/models/user-types";

const ApiKeyRow = (props) => {
  const [hoover, setHoover] = useState(null);
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const companyApiToken = useSelector(selectCompanyApiToken);
  const isLoading = useSelector(selectButtonDeleteApiKey);
  const apiKey = props.apiKey;

  const removeHandler = (apiKey) => {
    dispatch(deleteApiKey(companyApiToken, apiKey));
  };

  const onMouseEnterHandler = (button) => {
    setHoover(button);
  };

  const onMouseLeaveHandler = (button) => {
    setHoover(null);
  };

  return (
    <tr
      onMouseEnter={() => onMouseEnterHandler(apiKey.api_key)}
      onMouseLeave={() => onMouseLeaveHandler(apiKey.api_key)}
      className={classes["table-row"]}
    >
      <td>{apiKey.alias}</td>
      <td>
        <b>{apiKey.api_key}</b>
      </td>
      <td>
        <Badge bg="info">{apiKey.access_type}</Badge>
      </td>
      <td>{apiKey.allow_unbind.toString()}</td>
      <td className={classes["image-col"]}>
        {(userType === USERTYPES.ADMIN || userType === USERTYPES.OWNER) && (
          <Trash3Fill
            onClick={() => removeHandler(apiKey.api_key)}
            disabled={isLoading[apiKey.api_key]}
            display={hoover === apiKey.api_key ? "inline" : "none"}
          />
        )}
      </td>
    </tr>
  );
};

export default ApiKeyRow;
