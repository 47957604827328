import GenerateTokenCard from "./GenerateTokenCard";
import { useTranslation } from "react-i18next";

import googlePlayBadge from "../../assets/google-play-badge.png";

import classes from "./NoDevices.module.css";

const NoDevices = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <GenerateTokenCard></GenerateTokenCard>
      <div className={classes.download}>
        <span><b>{t("Don't have a device?")}</b> <br/>{t("get-rufus-app")}</span>
        <a
          href="https://play.google.com/store/apps/details?id=com.runonrufus.rufustiming&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="Get it on Google Play" width="220" src={googlePlayBadge} />
        </a>
      </div>
    </div>
  );
};

export default NoDevices;
