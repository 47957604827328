import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

import {
  registerNewUser,
  updateUserEmail,
  updateUserPhone,
  getUserData,
} from "./user-actions";

import { setLoading, resetForm } from "./ui-actions";

import { authActions } from "./auth-slice";
import userPool from "../user-pool";
import { ToastError, ToastSuccess } from "../components/Helpers/ToastHelper";

const clearStorage = () => {
  localStorage.removeItem("email");
  localStorage.removeItem("idToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

const setStorage = (email, sessionData) => {
  localStorage.setItem("email", email);
  localStorage.setItem("idToken", sessionData.idToken);
  localStorage.setItem("accessToken", sessionData.accessToken);
  localStorage.setItem("refreshToken", sessionData.refreshToken);
};

const getUserSession = () => {
  return new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser();

    if (user) {
      user.getSession((error, session) => {
        if (error) {
          reject(error);
        } else {
          resolve({
            user: user,
            session: session,
          });
        }
      });
    }
  });
};

const getCognitoUser = (email) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  return new CognitoUser(userData);
};

const updateUserAttributes = (user, attributeName, attributeValue) => {
  return new Promise((resolve, reject) => {
    const attributes = [
      new CognitoUserAttribute({ Name: attributeName, Value: attributeValue }),
    ];

    user.updateAttributes(attributes, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};

const extractSessionData = (session) => {
  const sessionData = {
    idToken: session.getIdToken().getJwtToken(),
    accessToken: session.getAccessToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
  const phoneValidated = session.getIdToken().payload["phone_number_verified"];
  const emailValidated = session.getIdToken().payload["email_verified"];
  const email = session.getIdToken().payload["email"];

  return {
    email,
    sessionData,
    emailValidated,
    phoneValidated,
  };
};

const refreshSession = (user, session) => {
  return new Promise((resolve, reject) => {
    user.refreshSession(session.getRefreshToken(), (error, session) => {
      if (error) {
        reject(error);
      } else {
        const { email, sessionData, emailValidated, phoneValidated } =
          extractSessionData(session);

        setStorage(email, sessionData);

        resolve({
          email,
          sessionData,
          emailValidated,
          phoneValidated,
        });
      }
    });
  });
};

export const getSession = () => {
  return async (dispatch) => {
    try {
      const { user, session } = await getUserSession();

      // console.log("getSession:::", session);

      // Refresh the session
      const { sessionData, email, emailValidated, phoneValidated } =
        await refreshSession(user, session);

      // Get the user information from MongoDB
      dispatch(getUserData(email));

      dispatch(
        authActions.setCredentials({
          email: email,
          sessionData: sessionData,
          isVerificationPending: !emailValidated,
          isPhoneVerificationPending: !phoneValidated,
        })
      );
    } catch (error) {
      clearStorage();
      dispatch(authActions.signOut());
    }
  };
};

export const signIn = (email, password) => {
  return async (dispatch) => {
    dispatch(setLoading("buttonAuthFormSubmit", true));

    const cognitoUser = getCognitoUser(email);
    const authenticationData = {
      Username: email,
      Password: password,
    };

    const authDetails = new AuthenticationDetails(authenticationData);

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session) => {
        dispatch(setLoading("buttonAuthFormSubmit", false));
        console.log("signIn:::", session);

        const { sessionData, emailValidated, phoneValidated } =
          extractSessionData(session);

        setStorage(email, sessionData);

        dispatch(
          authActions.setCredentials({
            email: email,
            sessionData: sessionData,
            isVerificationPending: !emailValidated,
            isPhoneVerificationPending: !phoneValidated,
          })
        );
      },

      onFailure: (error) => {
        dispatch(setLoading("buttonAuthFormSubmit", false));

        if (error.code === "UserNotConfirmedException") {
          localStorage.setItem("confirmation-email", email);
          ToastSuccess("EmailVerificationPending");
          dispatch(authActions.setVerificationPending());
          return;
        }
        ToastError(error);
      },
    });
  };
};

export const signUp = (email, password, invitation = null) => {
  return async (dispatch) => {
    dispatch(setLoading("buttonAuthFormSubmit", true));

    const dataEmail = {
      Name: "email",
      Value: email,
    };
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeList = [attributeEmail];

    // Add to Cognito Pool
    userPool.signUp(
      email,
      password,
      attributeList,
      null,
      async (error, result) => {
        if (error) {
          dispatch(setLoading("buttonAuthFormSubmit", false));
          ToastError(error);
          return;
        }

        // Add to MongoDB
        const registrationResult = await dispatch(
          registerNewUser(email, invitation)
        );

        dispatch(setLoading("buttonAuthFormSubmit", false));
        if (registrationResult === "SUCCESS") {
          localStorage.setItem("confirmation-email", email);
          ToastSuccess("EmailVerificationPending");
          dispatch(authActions.setVerificationPending());
        } else {
          ToastError(registrationResult);
        }
      }
    );
  };
};

export const signOut = () => {
  return async (dispatch) => {
    const user = userPool.getCurrentUser();

    if (user) {
      user.signOut();
    }
    clearStorage();

    //TODO: Clear all UI pendings
    dispatch(authActions.signOut());
  };
};

export const verifyEmail = (email, code) => {
  return async (dispatch) => {
    dispatch(setLoading("buttonAuthFormSubmit", true));
    const cognitoUser = getCognitoUser(email);

    cognitoUser.confirmRegistration(code, true, (error, result) => {
      dispatch(setLoading("buttonAuthFormSubmit", false));
      if (error) {
        ToastError(error);
        return;
      }
      localStorage.removeItem("confirmation-email");
      ToastSuccess("EmailVerificationSuccess");

      dispatch(authActions.signOut()); // => Go to login
    });
  };
};

export const verifyNewEmail = (code) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonConfirmNewEmail", true));
      const { user } = await getUserSession();

      user.verifyAttribute("email", code, {
        onSuccess: (result) => {
          dispatch(setLoading("buttonConfirmNewEmail", false));
          dispatch(authActions.newEmailVerified());
          ToastSuccess("NewEmailVerificationSuccess");
        },

        onFailure: (error) => {
          dispatch(setLoading("buttonConfirmNewEmail", false));
          ToastError(error, "verifyNewEmail");
        },
      });
    } catch (error) {
      dispatch(setLoading("buttonConfirmNewEmail", false));
      ToastError(error);
    }
  };
};

export const verifyNewPhone = (code) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonConfirmNewPhone", true));
      const { user } = await getUserSession();

      user.verifyAttribute("phone_number", code, {
        onSuccess: (result) => {
          dispatch(setLoading("buttonConfirmNewPhone", false));
          dispatch(authActions.newPhoneVerified());
          ToastSuccess("PhoneVerificationSuccess");
        },

        onFailure: (error) => {
          dispatch(setLoading("buttonConfirmNewPhone", false));
          ToastError(error,"verifyNewPhone");
        },
      });
    } catch (error) {
      dispatch(setLoading("buttonConfirmNewPhone", false));
      ToastError(error);
    }
  };
};

export const reSendNewPhoneVerificationCode = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonPhoneSendAgain", true));
      const { user, session } = await getUserSession();

      user.getAttributeVerificationCode("phone_number", {
        onSuccess: (result) => {
          dispatch(setLoading("buttonPhoneSendAgain", false));
          ToastSuccess("PhoneConfirmationCodeSent");
        },

        onFailure: (error) => {
          dispatch(setLoading("buttonPhoneSendAgain", false));
          ToastError(error);
        },
      });
    } catch (error) {
      dispatch(setLoading("buttonPhoneSendAgain", false));
      ToastError(error);
    }
  };
};

export const reSendNewEmailVerificationCode = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonEmailSendAgain", true));
      const { user, session } = await getUserSession();

      user.getAttributeVerificationCode("email", {
        onSuccess: (result) => {
          dispatch(setLoading("buttonEmailSendAgain", false));
          ToastSuccess("ConfirmationCodeSent");
        },

        onFailure: (error) => {
          dispatch(setLoading("buttonEmailSendAgain", false));
          ToastError(error);
        },
      });
    } catch (error) {
      dispatch(setLoading("buttonEmailSendAgain", false));
      ToastError(error);
    }
  };
};

export const reSendVerificationCode = (email) => {
  return async (dispatch) => {
    dispatch(setLoading("buttonAuthFormSend", true));
    const cognitoUser = getCognitoUser(email);

    cognitoUser.resendConfirmationCode((error, response) => {
      dispatch(setLoading("buttonAuthFormSend", false));
      if (error) {
        ToastError(error);
      } else {
        ToastSuccess("ConfirmationCodeSent");
      }
    });
  };
};

export const sendRecoveryToken = (email) => {
  return async (dispatch) => {
    dispatch(setLoading("buttonAuthFormSend", true));
    const cognitoUser = getCognitoUser(email);

    cognitoUser.forgotPassword({
      onSuccess: (response) => {
        dispatch(setLoading("buttonAuthFormSend", false));
        localStorage.setItem("confirmation-email", email);

        ToastSuccess("RecoveryTokenSent");

        dispatch(authActions.setChangePasswordPending());
      },

      onFailure: (error) => {
        dispatch(setLoading("buttonAuthFormSend", false));
        ToastError(error);
      },
    });
  };
};

export const resetPassword = (email, newPassword, resetCode) => {
  return async (dispatch) => {
    dispatch(setLoading("buttonAuthFormSubmit", true));
    const cognitoUser = getCognitoUser(email);

    cognitoUser.confirmPassword(resetCode, newPassword, {
      onSuccess: (response) => {
        dispatch(setLoading("buttonAuthFormSubmit", false));
        localStorage.removeItem("confirmation-email");

        ToastSuccess("ResetPasswordSuccess");

        dispatch(authActions.signOut()); // => Go to login
      },

      onFailure: (error) => {
        dispatch(setLoading("buttonAuthFormSubmit", false));
        ToastError(error);
      },
    });
  };
};

export const changePassword = (currentPassword, newPassword) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonChangePassword", true));

      const { user } = await getUserSession();

      user.changePassword(currentPassword, newPassword, (error, response) => {
        dispatch(setLoading("buttonChangePassword", false));
        if (error) {
          ToastError(error, "changePassword");
        } else {
          dispatch(resetForm("changePasswordForm"));
          ToastSuccess("ChangePasswordSuccess");
        }
      });
    } catch (error) {
      dispatch(setLoading("buttonChangePassword", false));
      ToastError(error, "changePassword");
    }
  };
};

export const changeEmail = (newEmail, userApiToken) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonSaveNewEmail", true));
      const { user, session } = await getUserSession();

      // Update the user email in Cognito
      await updateUserAttributes(user, "email", newEmail);

      // Update the user email in MongoDB
      await dispatch(updateUserEmail(newEmail, userApiToken));

      // Refresh the session
      const { sessionData } = await refreshSession(user, session);

      dispatch(setLoading("buttonSaveNewEmail", false));

      // Update de user in auth-slice Redux
      dispatch(
        authActions.setCredentials({
          email: newEmail,
          sessionData: sessionData,
          isVerificationPending: true,
        })
      );

      ToastSuccess("EmailVerificationPending");
    } catch (error) {
      dispatch(setLoading("buttonSaveNewEmail", false));
      ToastError(error);
    }
  };
};

export const changePhoneNumber = (countryCode, phoneNumber, userApiToken) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading("buttonSaveNewPhone", true));
      const strCountryCode = countryCode.toString().trim();
      const strPhoneNumber = phoneNumber.toString().trim();
      const newPhoneNumber = strCountryCode.concat(strPhoneNumber);
      const { user, session } = await getUserSession();

      // Update the user phone in Cognito
      await updateUserAttributes(user, "phone_number", newPhoneNumber);

      // Update the user phone in MongoDB
      await dispatch(
        updateUserPhone(strCountryCode, strPhoneNumber, userApiToken)
      );

      // Refresh the session
      await refreshSession(user, session);

      dispatch(setLoading("buttonSaveNewPhone", false));

      // Update de user in auth-slice Redux
      dispatch(authActions.setPhoneVerificationPending());

      if (newPhoneNumber === "") {
        ToastSuccess("PhoneRemoved");
      } else {
        ToastSuccess("PhoneVerificationPending");
      }
    } catch (error) {
      dispatch(setLoading("buttonSaveNewPhone", false));
      ToastError(error, "changePhoneNumber");
    }
  };
};
