/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const saveEventPublicationDetails = /* GraphQL */ `
  mutation SaveEventPublicationDetails(
    $event_token: ID!
    $event_details: EventPublicationDetailsInput
  ) {
    saveEventPublicationDetails(
      event_token: $event_token
      event_details: $event_details
    )
  }
`;
export const generateTokenDevice = /* GraphQL */ `
  mutation GenerateTokenDevice($api_token: ID!) {
    generateTokenDevice(api_token: $api_token) {
      api_token
      token_device
      token_until
    }
  }
`;
export const changeDeviceAlias = /* GraphQL */ `
  mutation ChangeDeviceAlias(
    $company_api_token: ID!
    $device_id: ID!
    $new_alias: String!
  ) {
    changeDeviceAlias(
      company_api_token: $company_api_token
      device_id: $device_id
      new_alias: $new_alias
    )
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation($invitation: UserInvitationInput!) {
    createInvitation(invitation: $invitation) {
      id
      invitation_email
      company_api_token
      inviter_api_token
      hash
      user_type
      consumed
    }
  }
`;
export const removeInvitation = /* GraphQL */ `
  mutation RemoveInvitation($hash: ID!) {
    removeInvitation(hash: $hash)
  }
`;
export const removeCollaborator = /* GraphQL */ `
  mutation RemoveCollaborator($id: ID!) {
    removeCollaborator(id: $id)
  }
`;
export const registerUser = /* GraphQL */ `
  mutation RegisterUser($email: String!, $invitation_hash: String) {
    registerUser(email: $email, invitation_hash: $invitation_hash)
  }
`;
export const updateUserType = /* GraphQL */ `
  mutation UpdateUserType($id: ID!, $user_type: String!) {
    updateUserType(id: $id, user_type: $user_type)
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($user: UserInput!) {
    updateUser(user: $user)
  }
`;
export const createSmsToken = /* GraphQL */ `
  mutation CreateSmsToken($id: ID!, $telephone: String!) {
    createSmsToken(id: $id, telephone: $telephone)
  }
`;
export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey(
    $company_api_token: ID!
    $alias: String!
    $access_type: String!
    $allow_unbind: Boolean
  ) {
    createApiKey(
      company_api_token: $company_api_token
      alias: $alias
      access_type: $access_type
      allow_unbind: $allow_unbind
    )
  }
`;
export const removeApiKey = /* GraphQL */ `
  mutation RemoveApiKey($company_api_token: ID!, $api_key: ID!) {
    removeApiKey(company_api_token: $company_api_token, api_key: $api_key)
  }
`;
