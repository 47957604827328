import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Table from "react-bootstrap/Table";

import { getApiKeys } from "../../store/user-actions";

import { selectApiKeys, selectCompanyApiToken } from "../../store/user-slice";

import classes from "./ApiKeysTable.module.css";

import ApiKeyRow from "./ApiKeyRow";

const ApiKeysTable = () => {
  const { t } = useTranslation();
  const apiKeysList = useSelector(selectApiKeys);
  const companyApiToken = useSelector(selectCompanyApiToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyApiToken) {
      dispatch(getApiKeys(companyApiToken));
    }
  }, [dispatch, companyApiToken]);

  return (
    <div className={classes["table-container"]}>
      {apiKeysList.length > 0 && (
        <>
          <h5>{t("Active api keys")}</h5>{" "}
          <h6>{t("active-api-keys-subtitle")}</h6>
          <Table className={classes.table} striped hover responsive>
            <thead>
              <tr>
                <th>{t("Alias")}</th>
                <th>{t("Api key")}</th>
                <th>{t("Access type")}</th>
                <th>{t("Allow unbind")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {apiKeysList.map((apiKey) => (
                <ApiKeyRow key={apiKey.api_key} apiKey={apiKey} />
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default ApiKeysTable;
