import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";

import classes from "./SessionsList.module.css";

import SessionItem from "./SessionItem";

const SessionsList = (props) => {
  const sessions = props.sessions;

  return (
    <Container>
      <ListGroup className={classes.list} as="ol" numbered>
        {sessions.map((session) => (
          <SessionItem key={session._id} session={session} />
        ))}
      </ListGroup>
    </Container>
  );
};

export default SessionsList;
