import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import classes from "./DeviceCard.module.css";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

import imgRufusAndroid from "../../assets/rufus-android-app.png";

const DeviceCard = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const device = props.device;

  const clickHandler = (id) => {
    navigate("/devices/"+id);
  };

  return (
    <Col>
      <Card className={classes.card} onClick={() => clickHandler(device.id)}>
        <Card.Header>{device.type}</Card.Header>

        <Card.Img variant="top" src={imgRufusAndroid} />

        <Card.Body>
          <Card.Title>{device.alias} </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {device.serial_number}
          </Card.Subtitle>
          <Card.Text className="text-muted">{device.numOfSessions} {t("sessions availables")}</Card.Text>
        </Card.Body>

        <Card.Footer className="text-muted">{t("Active")} {device.last_login}</Card.Footer>
      </Card>
    </Col>
  );
};

export default DeviceCard;
