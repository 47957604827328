import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  email: null,
  sessionData: {
    idToken: null,
    accessToken: null,
    refreshToken: null,
  },
  isAuthenticated: false,
  isVerificationPending: false,
  isPhoneVerificationPending: false,
  isChangePasswordPending: false,
};

const setInitialAuthState = () => {
  const email = localStorage.getItem("email") || null;
  const sessionData = {
    idToken: localStorage.getItem("idToken") || null,
    accessToken: localStorage.getItem("accessToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
  };

  if (sessionData && email) {
    return {
      email: email,
      sessionData: sessionData,
      isAuthenticated: true,
      isVerificationPending: false,
      isPhoneVerificationPending: false,
    };
  }

  return initialAuthState;
};

const authSlice = createSlice({
  name: "auth",
  initialState: setInitialAuthState(),
  reducers: {
    setCredentials(state, action) {
      const {
        email,
        sessionData,
        isVerificationPending,
        isPhoneVerificationPending,
      } = action.payload;

      state.isAuthenticated = sessionData.accessToken ? true : false;
      state.isVerificationPending = isVerificationPending;
      state.isPhoneVerificationPending = isPhoneVerificationPending;
      state.isChangePasswordPending = false;
      state.email = email;
      state.sessionData = sessionData;
    },
    setVerificationPending(state) {
      state.isVerificationPending = true;
    },
    setPhoneVerificationPending(state) {
      state.isPhoneVerificationPending = true;
    },
    setChangePasswordPending(state) {
      state.isChangePasswordPending = true;
    },
    newEmailVerified(state) {
      state.isVerificationPending = false;
    },
    newPhoneVerified(state) {
      state.isPhoneVerificationPending = false;
    },
    signOut(state) {
      return initialAuthState;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.email;
export const selectCurrentToken = (state) => state.auth.token;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectIsVerificationPending = (state) =>
  state.auth.isVerificationPending;
export const selectIsPhoneVerificationPending = (state) =>
  state.auth.isPhoneVerificationPending;
export const selectIsChangePasswordPending = (state) =>
  state.auth.isChangePasswordPending;
