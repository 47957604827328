import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AllDevices from "../components/Devices/AllDevices";
import AllSessions from "../components/Sessions/AllSessions";
import AllPassings from "../components/Passings/AllPassings";
import Bread from "../components/UI/Bread";

import {
  getDevicesList,
  getSessionsList,
  getPassingsList,
} from "../store/devices-actions";

import {
  selectDevicesList,
  selectSessionsList,
} from "../store/devices-slice";

import { selectCompanyApiToken } from "../store/user-slice";

const DevicesPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { deviceId, sessionId } = params;
  const [currentDevice, setCurrentDevice] = useState({});
  const [currentSession, setCurrentSession] = useState({});
  const companyApiToken = useSelector(selectCompanyApiToken);
  const devices = useSelector(selectDevicesList);
  const sessions = useSelector(selectSessionsList);

  useEffect(() => {
    if (!companyApiToken) return;

    if (!devices) {
      dispatch(getDevicesList(companyApiToken));
    }
  }, [companyApiToken, devices, dispatch]);

  useEffect(() => {
    if (!companyApiToken) return;

    if (deviceId) {
      dispatch(getSessionsList(companyApiToken, deviceId));
    }
  }, [companyApiToken, deviceId, dispatch]);

  useEffect(() => {
    if (!companyApiToken) return;

    if (sessionId) {
      dispatch(getPassingsList(sessionId));
    }
  }, [companyApiToken, sessionId, dispatch]);

  useEffect(() => {
    if (deviceId && devices && devices.length) {
      const device = devices.find((d) => d.id === deviceId);

      setCurrentDevice(device);
    }else{
      setCurrentDevice({});
    }
  }, [devices, deviceId]);

  useEffect(() => {
    if (sessionId && sessions && sessions.length) {
      const session = sessions.find((s) => s._id === sessionId);

      setCurrentSession(session);
    }else{
      setCurrentSession({});
    }
  }, [sessions, sessionId]);

  return (
    <>
      {!deviceId && !sessionId ? (
        <AllDevices></AllDevices>
      ) : deviceId && !sessionId ? (
        <>
          <Bread
            currentDevice={currentDevice}
            currentSession={currentSession}
          ></Bread>
          <AllSessions currentDevice={currentDevice}></AllSessions>
        </>
      ) : deviceId && sessionId ? (
        <>
          <Bread
            currentDevice={currentDevice}
            currentSession={currentSession}
          ></Bread>
          <AllPassings
            currentDevice={currentDevice}
            currentSession={currentSession}
          ></AllPassings>
        </>
      ) : null}
    </>
  );
};

export default DevicesPage;
